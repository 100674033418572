import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SbdDetailView from './View';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../data/services';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import SbdDialog from '../components/sbdDialog';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import CreateDialog from '../components/CreateDialog/CreateDialog';
import { getPermissionForSbd } from '../../../base/permission';

const propTypes = {
  getSBDBasicDetail: PropTypes.func.isRequired,
  getSkuDetails: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  insertSbdDetails: PropTypes.func.isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Details extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      menu: {
        channelList: [],
        categoryList: [],
      },
      channelId: 0,
      categoryList: [],
      categoryId: 0,
      brandList: [],
      brandId: 0,
      skuList: [],
      skuGroupList: [],
      skuId: 0,
      sbdId: parseInt(props.match.params.id, 10),
      showBrands: false,
      skus: [],
      dialog: {
        type: '',
        element: '',
      },
      basicDetail: {},
      checkedSkuFamilies: [],
      sbdDetails: {
        Channel: [],
        Category: [],
        Brand: [],
        SkuGroup: [],
      },
      totalSkuFamilies: [],
      sbdSKUCountList:[],
    };
    this.permission = getPermissionForSbd();
  }

  componentDidMount() {
    this.loadDataForDropDown();
    this.getBrandDetails();
    this.getSBDBasicDetails();
    this.getSBDSKUsCount({});
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then(response => {
      menu.channelList = response;
      this.setState({ menu });
    });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then(response => {
      menu.categoryList = response;
      this.setState({ menu });
    });
  };

  getSBDBasicDetails = () => {
    const { getSBDBasicDetail, match } = this.props;
    const { id } = match.params;

    getSBDBasicDetail(
      {
        value: id,
      },
      {
        handleSuccess: response => {
          const { sbds } = response.data;
          this.setState({
            basicDetail: sbds.rows[0],
          });
        },
      },
    );
  };

  getSBDSKUsCount = data => {
    const { getSBDCount, match } = this.props;
    const { id } = match.params;

    getSBDCount(
      {
        sbdId: parseInt(id, 10),
        ...data,
      },
      {
        handleSuccess: response => {
          const { getSbdDetails } = response.data;
          const details = getSbdDetails.rows[0];
          const groupedSkus = details ? details.SkuFamily.filter(sku => sku.skus.length > 1) : [];
          const sbdSKUCountList = details?.SkuGroup;
          // debugger
          this.setState(
            {
              sbdDetails: details || [],
              skuGroupList: groupedSkus,
              sbdSKUCountList,
              // skuList: skuFamilyy.filter
            },
            () => {
              if (data.brandId) {
                const skuFamilyy = details?.SkuFamily;
                this.getSkuDetails(data.brandId,skuFamilyy);
              }
            },
          );
        },
        handleError: err => {
          this.handleApiFailure(err);
        },
      },
    );
  };

  getBrandDetails = (id = 3) => {
    const { getBrandDetails, displayAlert } = this.props;

    getBrandDetails(
      {
        catalogId: id,
      },
      {
        handleSuccess: response => {
          this.setState({
            brandList: response.data.catalogDetails.rows,
          });
          displayAlert(ALERT_TYPE.SUCCESS, 'Fetched');
        },
      },
    );
  };

  getSkuDetails = (id,skuFamilyList) => {
    const { skuGroupList } = this.state;
    const { getSkuDetails } = this.props;

    getSkuDetails(
      {
        value: id,
      },
      {
        handleSuccess: response => {
          const { catalogLevelDetails } = response.data;
          const catalogSkus = catalogLevelDetails.rows[0].SkuFamilies;

          const groupedSkus = skuGroupList;
          const filterSku = catalogSkus.filter(sku => groupedSkus.find(grouped => grouped.skus.includes(sku.id)));
          const nonFilteredSku = catalogSkus.filter(sku => !groupedSkus.find(grouped => grouped.skus.includes(sku.id)));
          const skuFamilywithoutSKUS = nonFilteredSku.filter(a=>a.skuCount!==0) // filter SkuFamilies having skus

          this.setState({
            skuList: skuFamilywithoutSKUS,
            skus: [],
            checkedSkuFamilies: [],
            totalSkuFamilies: catalogSkus,
            skuGroupList: filterSku.length !== 0 ? skuGroupList : [],
          });

        },
      },
    );
  };

  onBrandSelect = id => {
    const { channelId, categoryId } = this.state;
    this.setState({
      brandId: id,
      skuList: [],
    });
    this.getSBDSKUsCount({
      channelId,
      categoryId,
      brandId: id,
    });
  };

  onChannelSelect = id => {
    const { menu } = this.state;
    this.setState({
      categoryList: menu.categoryList.filter(d => d.Channel.id === id),
      channelId: id,
      skus: [],
      checkedSkuFamilies: [],
      categoryId: null,
      showBrands: false,
      skuList: [],
      sbdDetails: {
        Channel: [],
        Category: [],
        Brand: [],
        SkuGroup: [],
      },
    });
    this.getSBDSKUsCount({
      channelId: id,
    });
  };

  onCategorySelect = categoryId => {
    const { channelId, brandId } = this.state;
    this.setState({
      showBrands: true,
      categoryId,
    });
    this.getSBDSKUsCount({
      channelId,
      categoryId,
      brandId,
    });
  };

  handleSBDSubmitSKU = (id, toggleStatus) => {
    const { insertSbdDetails, displayAlert, deleteSbdDetails } = this.props;
    const { sbdId, channelId, categoryId, brandId, skus, sbdDetails, skuGroupList, totalSkuFamilies } = this.state;
    const submittingData = {
      sbdId,
      channelId,
      categoryId,
      brandId,
      skuFamilies: id,
    };
    if (toggleStatus) {
      skus.push(parseInt(id.toString(), 10));
      insertSbdDetails(
        {
          input: submittingData,
        },
        {
          handleSuccess: () => {
            this.getSBDSKUsCount({
              ...submittingData,
            });
            displayAlert(ALERT_TYPE.INFO, 'Update Successfully');
          },
          handleError: err => {
            this.handleApiFailure(err);
          },
        },
      );
    } else {
      const findGroup = sbdDetails.SkuFamily.filter(group => group.skus.includes(parseInt(id.toString(), 10)))[0].id;
      deleteSbdDetails(
        {
          id: findGroup,
        },
        {
          handleSuccess: () => {
            this.getSBDSKUsCount({
              ...submittingData,
            });
            this.setState({
              skuGroupList: skuGroupList.filter(d => d.id !== findGroup),
              skuList: totalSkuFamilies,
            });
            displayAlert(ALERT_TYPE.INFO, 'Disabled Successfully');
          },
        },
      );
    }
  };

  handleIconClick = action => {
    // debugger
    const { basicDetail } = this.state;
    this.setState({
      dialog: {
        type: action,
        element: basicDetail,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: {},
      },
    });
  };

  handleDialogSubmit = type => {
    const { history } = this.props;

    if (type === EVENT_OPERATION.REPLICATE || type === EVENT_OPERATION.DELETE) {
      history.push('/configuration/sbd');
    }
    this.getSBDBasicDetails();
  };

  handleApiFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleDialogClose();
  };

  setCheckedSkuFamilies = skuFamily => {
    const { checkedSkuFamilies } = this.state;
    if (!checkedSkuFamilies.includes(skuFamily)) {
      this.setState({
        checkedSkuFamilies: [...checkedSkuFamilies, skuFamily],
      });
    } else {
      this.setState({
        checkedSkuFamilies: checkedSkuFamilies.filter(d => d !== skuFamily),
      });
    }
  };

  onCreateGroupClick = () => {
    this.setState({
      dialog: {
        type: EVENT_OPERATION.CREATE_GROUP,
      },
    });
  };

  handleGroupCreate = () => {
    const { insertSbdDetails, displayAlert } = this.props;
    const { sbdId, channelId, categoryId, brandId, checkedSkuFamilies, skuGroupList } = this.state;

    const createGroup = {
      sbdId,
      channelId,
      categoryId,
      brandId,
      skuFamilies: checkedSkuFamilies,
    };

    insertSbdDetails(
      {
        input: createGroup,
      },
      {
        handleSuccess: response => {
          const { insertSbdDetails: details } = response.data;
          this.setState(
            {
              checkedSkuFamilies: [],
              skuGroupList: [
                ...skuGroupList,
                {
                  id: details.id,
                  count: details.sku_family.length,
                  skus: details.sku_family,
                },
              ],
            },
            () => {
              this.getSBDSKUsCount({
                channelId,
                categoryId,
                brandId,
              });
            },
          );
          displayAlert(ALERT_TYPE.INFO, 'Created Successfully');
        },
        handleError: err => {
          this.handleApiFailure(err);
        },
      },
    );
  };

  render() {
    const { dialog } = this.state;
    const { serverResponseWaiting,getSBDSku } = this.props;
    return (
      <>
        <SbdDetailView
          {...this.state}
          onIconClick={this.handleIconClick}
          onChannelSelect={this.onChannelSelect}
          loading={serverResponseWaiting}
          onCategorySelect={this.onCategorySelect}
          getSkuDetails={this.onBrandSelect}
          handleSBDSubmit={this.handleSBDSubmitSKU}
          setCheckedSkuFamilies={this.setCheckedSkuFamilies}
          onCreateGroup={this.onCreateGroupClick}
          getSBDSku={getSBDSku}
          permission={this.permission}
        />
        {dialog.type && (
          <div className="sbd-modal">
            <SbdDialog
              type={dialog.type}
              element={dialog.element}
              onSubmit={this.handleDialogSubmit}
              onClose={this.handleDialogClose}
              onApiFailure={this.handleApiFailure}
            />
          </div>
        )}
        {dialog.type === EVENT_OPERATION.CREATE_GROUP && (
          <div className="sbd-modal">
            <CreateDialog
              type={dialog.type}
              element={dialog.element}
              onSubmit={this.handleGroupCreate}
              onClose={this.handleDialogClose}
              onApiFailure={this.handleApiFailure}
            />
          </div>
        )}
      </>
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
