import { InMemoryCache } from 'apollo-cache-inmemory';

import {
  AUTH, VERSION, CLIENT_AUTH_REQUEST_TYPE, CLIENT_AUTHENTICATION_METHOD, JWT
} from '../../environment';
import { BU_DSE_LABEL, BU_LABEL } from '../../data/enums/config';
import { USER_ROLE_TITLE } from '../../data/enums/UserRole';
import { isObjectEmpty } from '../../utils/objectPrototypes';

const cache = new InMemoryCache();
/** const apolloCache = new InMemoryCache({
  dataIdFromObject: e => `${e.__typename}_${e.id}` || null,
}); */

const useLocalStorage = CLIENT_AUTHENTICATION_METHOD.LOCAL_STORAGE;

const httpOptions = {
  credentials: 'same-origin',
  headers: {
    'frontend-version': VERSION,
    [AUTH.STRATEGIES.CLIENT.AUTH_HEADER]: CLIENT_AUTH_REQUEST_TYPE,
  },
};

const webSocketOptions = {
  options: {
    reconnect: true,
  },
  connectionParams: {},
};

const clientOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const getHeaderLabelObj = () => {
  const server = window.location.origin;
  const label = Object.values(BU_LABEL).find((bu) => {
    if (server.includes(bu)) return true;
    return false;
  }) || BU_LABEL.PG;
  return { [USER_ROLE_TITLE.DSE]: BU_DSE_LABEL[label] };
};

const storeInStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const setDefaultLabel = () => {
  if (isObjectEmpty(JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.LABEL.HEADER)) || {})) {
    storeInStorage(JWT.LOCAL_STORAGE.LABEL.HEADER, getHeaderLabelObj());
  }
};

setDefaultLabel();

const devUrl = 'https://v3.rosia.one/api';
const prodUrl = `${window.location.origin}/api`;
const url = {
  http: process.env.NODE_ENV === 'development' ? devUrl : prodUrl, // process.env.REACT_APP_API_URL,
  webSocket: `wss://${window.location.hostname}/api/ws`, // process.env.REACT_APP_WEB_SOCKET_URL,
  // webSocket: 'wss://127.0.0.1:8001/api/ws', // process.env.REACT_APP_WEB_SOCKET_URL,
};

export {
  url, httpOptions, webSocketOptions, cache, useLocalStorage, clientOptions
};

// export default defaultOptions;
