import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_SUB_D_LIST } from '../../configuration/subD/API';

const CREATE_LEDGER = graphql(
  gql`
    mutation ($input: LedgerInput!) {
      createLedger(input: $input) {
        customerId
        title
        address
        panNumber
        phoneNumber
        typeId
        openingBalanceType
        openingBalance
        id
        creditDay
        creditLimit
        distributorId
        billingLedgerId
        externalCode
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createLedger: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const EDIT_LEDGER = graphql(
  gql`
    mutation editLedger($id: Int!, $input: LedgerUpdateInput!) {
      editLedger(id: $id, input: $input) {
        id
        name
        address
        panNumber
        billingLedgerId
        creditLimit
        creditDay
        phoneNumber
        externalCode
        RetailOutlet {
          title
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      editLedger: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_BILLING_CUSTOMERS = gql`
  query billingCustomers($distributorId: Int!, $searchText: String) {
    billingCustomers(distributorId: $distributorId, searchText: $searchText) {
      rows {
        title
        address
        typeId
        panNo
        customerId
        openingBalanceType
        openingBalance
        id
        creditDay
        creditLimit
        distributorId
        externalCode
      }
      count
    }
  }
`;

const mutation = {
  createLedger: CREATE_LEDGER,
  editLedger: EDIT_LEDGER,
};

const query = {
  billingCustomers: GET_BILLING_CUSTOMERS,
  getDistributors: GET_SUB_D_LIST,
};

export {
  mutation, CREATE_LEDGER, query, EDIT_LEDGER
};
