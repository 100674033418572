import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import {
  getDistributorServiceStatus,
  getUpdatedLabel,
  handlePrint,
  headerLabelConfig,
} from '../../../common/HelperFunctions';
import { DISTRIBUTOR_SERVICES } from '../../../common/DomainConfig';
import { USER_ROLE } from '../../../../data/enums';
import { getUserRole } from '../../../../data/dao';
import { Icon,Badge } from '../../../../v4/components';
import { normalPresentor } from '../../../../utils/date';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { getParametersBasedOnType } from '../dispatched/tableConfig';
import TableHeader from '../../../base/TableHeader';
import { DOMAIN } from '../../../../data/enums/config';

// todo update the configurations.
const labelMappings = {
  id: 'invoiceNumber',
  date: 'date',
  outlet: 'outlet',
  route: 'route',
  dse: 'dse',
  totalNetValue: 'totalNetValue',
  distributor: 'distributor',
  channel: 'channel',
  category: 'category',
  channelAndCategory: 'channel',
};

const returnTableBody = (invoiced, onRowClick) => {
  const table = {};
  const userRole = getUserRole();
  const printStatus =
    getDistributorServiceStatus(invoiced.Distributor, DISTRIBUTOR_SERVICES.BILLING) &&
    userRole === USER_ROLE.BILLING_USER;
  const data = {
    ...invoiced,
    ...getParametersBasedOnType(invoiced),
  };
  table[labelMappings.id] = (
    <td key={`${data.id}-id`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {
        /** todo chip implementation here */
        <span>
          <span className='tab-label'>{data.invoiceNumber}</span>
          <Badge light label={data.orderIds.length} />
        </span>
      }
    </td>
  );
  table[labelMappings.date] = (
    <td key={`${data.id}-date`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {normalPresentor(data.invoiceDate)}
    </td>
  );
  table[labelMappings.outlet] = (
    <td key={`${data.id}-outlet`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      <a href={`outlet/details/${data.RetailOutlet.id}`} onClick={(e) => e.preventDefault()}>
        {data.RetailOutlet ? data.RetailOutlet.title : ''}
      </a>
    </td>
  );
  table[labelMappings.channel] = (
    <td key={`${data.id}-channel`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.Channel && data.Category ? `${data.Channel.title}/${data.Category.title}` : ''}
    </td>
  );
  table[labelMappings.route] = (
    <td key={`${data.id}-route`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.Route ? data.Route.title : ''}
    </td>
  );
  table[labelMappings.dse] = (
    <td key={`${data.id}-dse`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.User ? data.User.fullName : ''}
    </td>
  );

  table[labelMappings.totalNetValue] = (
    <td key={`${data.id}-total`} className='text-right' onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {fixedFloatAndCommas(data.amountDetails ? data.amountDetails.netAmount || 0 : 0)}
    </td>
  );
  table[labelMappings.distributor] = (
    <td key={`${data.id}-distributor`} onClick={() => onRowClick(data.id, data.Distributor.id)} className='distributor'>
      <div className='span-wrap'>
        <span>{data.Distributor.title}</span>
        {printStatus && (
          <span>
            <Icon
              iconName='print'
              onClick={() =>
                handlePrint({
                  distributorServices: data.Distributor.servicesUsed,
                  invoiceNumber: data.invoiceNumber,
                  firstCopy: true,
                  module: data.Distributor.servicesUsed.billing.versionCode=== 2?DOMAIN.DBS_SALES:DOMAIN.SALES,
                })
              }
            />
          </span>
        )}
      </div>
    </td>
  );

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'Invoice Number',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Invoice Date',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 4,
    label: labelMappings.channel,
    title: 'Channel/Category',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 5,
    label: labelMappings.route,
    title: 'Route',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 6,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: true,
    className: 'sorting',
    sortable: true,
    isDynamic: true,
  },
  {
    id: 7,
    label: labelMappings.totalNetValue,
    title: 'Invoice Value',
    show: true,
    className: 'sorting text-right',
    sortable: true,
    align: true,
  },
  {
    id: 8,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
    className: 'sorting',
    sortable: true,
  },
];

const TableBody = ({ data, onRowClick }) => {
  const tableBody = returnTableBody(data, onRowClick);

  return <Fragment>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

const TableHeaderConfig = ({ onHeaderClick, dynamicHeader }) => (
  <TableHeader
    headerDetails={dynamicHeader ? getUpdatedLabel(labelConfig) : labelConfig}
    onHeaderClick={onHeaderClick}
  />
);

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export { TableHeaderConfig as TableHeader, TableBody };
