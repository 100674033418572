import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import Form from './Form';
import UploadForm from '../UploadForm';
import { DialogFormWrapper, DialogWrapper } from '../../../common';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { crudSuccess as crudRequestConfig, formConfig, title } from './config';
import { breadCrumbConfig } from '../config';
import { FORM_CONFIG } from '../../../../data/enums/config';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { TARGET_BRAND_COVERAGE_DETAILS } from '../../../../data/enums/Route';
import { crudResponseProcessor, handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import { Icon } from '../../../../v4/components';
import BrandCoverageWrapper from './BrandCoverageWrapper';
import { getStartOfCurrentMonth } from '../../../../utils/date';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { getPermissionForTargets } from '../../../base/permission';
import { isError } from '../../../common/HelperFunctions';

const propTypes = {
  downloadReport: PropTypes.func,
  upload: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  history: PropTypes.instanceOf(Object),
  displayAlert: PropTypes.func.isRequired,
  getCatalogDetailList: PropTypes.func.isRequired,
  getbrandCoverageTagets: PropTypes.func.isRequired,
  createBrandCoverageTarget: PropTypes.func.isRequired,
  updateBrandCoverageTarget: PropTypes.func.isRequired,
  getbrandCoverageTagetsList: PropTypes.func.isRequired,
};

const defaultProps = {
  history: {},
  downloadReport: () => null,
  serverResponseWaiting: false,
};
class BrandCoverage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      dialog: {
        type: '',
        element: '',
      },
      crudMode: '',
      titleWithouttype: false,
      brandDialogData: {
        id: 0,
        title,
        startDate: getStartOfCurrentMonth(),
        endDate: getStartOfCurrentMonth(),
        brandNameList: [
          {
            id: 0,
            brandId: 0,
            brandName: '',
            target: 0,
          },
        ],
      },
    };

    this.permission = getPermissionForTargets();

    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
    };

    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        let message = crudRequestConfig[type].message;
        if (type === EVENT_OPERATION.UPLOAD) {
          message = data;
        } else {
          this.setState(data);
        }
        this.loadTableData();
        displayAlert(ALERT_TYPE.SUCCESS, message);
      },
      this.getData,
      ['target'],
    );

    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { getbrandCoverageTagetsList } = this.props;
    const { data } = this.state;
    getbrandCoverageTagetsList(
      {},
      {
        handleSuccess: response => {
          data.list = (response.data.brandCoverageTargetsList && response.data.brandCoverageTargetsList.rows) || [];
          data.total = (response.data.brandCoverageTargetsList && response.data.brandCoverageTargetsList.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ data });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleIconClick = (type, element) => {
    this.updateDialog(type, element);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  updateDialog = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  onTableBodyClick = data => {
    const { history } = this.props;
    history.push(`/${TARGET_BRAND_COVERAGE_DETAILS}/${data.id}`, {
      title: data.title,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  };

  handleCSVUpload = type => {
    this.updateDialog(EVENT_OPERATION.UPLOAD, { name: type });
  };

  changeTitleFlag = type => {
    if (type === EVENT_OPERATION.READ) {
      this.setState({ titleWithouttype: true });
    } else {
      this.setState({ titleWithouttype: false });
    }
  };

  handleBrandDialog = (type, data) => {
    const { getbrandCoverageTagets, displayAlert, getCatalogDetailList } = this.props;
    const { brandDialogData } = this.state;
    if (type === EVENT_OPERATION.READ || type === EVENT_OPERATION.UPDATE) {
      getbrandCoverageTagets(
        {
          startDate: data.startDate,
          endDate: data.endDate,
        },
        {
          handleSuccess: response => {
            const brandNameList = response.data.brandCoverageTargets;
            this.setState(
              {
                brandDialogData: {
                  id: data.id,
                  startDate: data.startDate,
                  endDate: data.endDate,
                  title: brandNameList && brandNameList[0].title,
                  brandNameList,
                },
                crudMode: type,
              },
              () => this.changeTitleFlag(type),
            );
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
    if (type === EVENT_OPERATION.CREATE) {
      getCatalogDetailList(
        {
          catalogId: 3,
          limit: 50,
        },
        {
          handleSuccess: response => {
            const brandList = response.data.catalogDetails.rows;
            const brandNameList = brandList.map(brand => ({
              brandName: brand.title,
              target: 0,
              brandId: brand.id,
            }));
            this.setState({
              brandDialogData: { ...brandDialogData, title, brandNameList },
              crudMode: type,
            });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  onSampleDownload = entity => {
    const { downloadReport, displayAlert } = this.props;
    downloadReport(
      {
        input: {
          domain: entity,
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          const { downloadReport } = response.data;
          window.open(`${window.location.origin}/${downloadReport.file.name}`);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleInputChange = (event, params, index) => {
    const { brandDialogData } = this.state;
    if (params === 'target') {
      brandDialogData.brandNameList[index].target = event.target.value;
      this.setState(brandDialogData);
    }
    if (params === 'date') {
      brandDialogData[event.target.name] = event.target.value;
      this.setState(brandDialogData);
    }
  };

  brandDialogSubmit = () => {
    const { createBrandCoverageTarget, updateBrandCoverageTarget } = this.props;
    const { brandDialogData, crudMode } = this.state;
    switch (crudMode) {
      case EVENT_OPERATION.CREATE:
        createBrandCoverageTarget(
          {
            input: {
              startDate: brandDialogData.startDate || getStartOfCurrentMonth(),
              endDate: brandDialogData.endDate,
              brandTargets: brandDialogData.brandNameList.map(brand => ({ ...brand, target: Number(brand.target) })),
            },
          },
          {
            handleSuccess: () => {
              const { displayAlert } = this.props;
              displayAlert(ALERT_TYPE.SUCCESS, ` ${title} ${MESSAGE_EVENT_OPERATION.CREATE}`);
              this.loadTableData();
            },
            handleError: error => {
              this.onAPIRequestFailure(error);
            },
          },
        );
        break;
      case EVENT_OPERATION.UPDATE:
        updateBrandCoverageTarget(
          {
            startDate: brandDialogData.startDate,
            endDate: brandDialogData.endDate,
            input: brandDialogData.brandNameList.map(brand => ({
              id: brand.id,
              brandId: brand.brandId,
              brandName: brand.brandName,
              target: Number(brand.target),
            })),
          },
          {
            handleSuccess: () => {
              const { displayAlert } = this.props;
              displayAlert(ALERT_TYPE.SUCCESS, ` ${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
              this.loadTableData();
            },
            handleError: error => {
              this.onAPIRequestFailure(error);
            },
          },
        );
        break;
      default:
    }
  };

  render() {
    const { data, dialog, brandDialogData, crudMode, titleWithouttype } = this.state;
    const { type } = dialog;

    const { serverResponseWaiting } = this.props;
    const sampleUrl = checkCase => (
      <a onClick={() => this.onSampleDownload(checkCase)}>
        <span className="sample-csv">
          <Icon iconName="download" />
          Sample.csv
        </span>
      </a>
    );

    return (
      <BrandCoverageWrapper>
        {type && (
          <div className="targets-wrapper">
            <DialogFormWrapper
              type={type}
              dialogElement={dialog.element}
              formConfig={formConfig[type]}
              onDialogCancel={this.resetDialog}
              onDialogSubmit={this.onFormSubmit}
              fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
              withOutPadding
              renderDialog={({ dialogData, enableErrorDisplay, fileUploadRefs, handleFileUpload }) => (
                <Fragment>
                  {type === EVENT_OPERATION.UPLOAD && (
                    <>
                      <UploadForm
                        show
                        refsObj={fileUploadRefs}
                        data={dialogData}
                        loading={serverResponseWaiting}
                        handleFileUpload={handleFileUpload}
                        enableErrorDisplay={enableErrorDisplay}
                      />
                      {sampleUrl(DOWNLOAD_DOMAIN.BRAND_COVERAGE_USER_TARGET_SAMPLE)}
                    </>
                  )}
                </Fragment>
              )}
            />
          </div>
        )}
        <DialogWrapper
          title={brandDialogData.title}
          onDialogSubmit={this.brandDialogSubmit}
          withOutPadding
          titleWithouttype={titleWithouttype}
          renderDialog={({ type }) => (
            <Fragment>
              {(type === EVENT_OPERATION.CREATE ||
                type === EVENT_OPERATION.UPDATE ||
                type === EVENT_OPERATION.READ) && (
                <Form show type={type} data={brandDialogData} handleInputChange={this.handleInputChange} />
              )}
            </Fragment>
          )}
          render={({ onDialogItemClick }) => (
            <View
              data={data}
              breadCrumb={breadCrumbConfig}
              loading={serverResponseWaiting}
              onTableBodyClick={this.onTableBodyClick}
              onCSVUpload={this.handleCSVUpload}
              handleBrandDialog={this.handleBrandDialog}
              onIconClick={onDialogItemClick}
              permission={this.permission}
            />
          )}
        />
      </BrandCoverageWrapper>
    );
  }
}

BrandCoverage.propTypes = propTypes;

BrandCoverage.defaultProps = defaultProps;

export default withAlert()(BrandCoverage);
