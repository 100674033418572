import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  BreadCrumb,Badge,Button
} from '../../../../v4/components';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { breadCrumb } from './config';
import CustomSelect from '../../../../components/CustomSelect';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import SBDDetailStyled from '../../sbd/details/SBDDetailStyled';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import BasicDetail from './BasicDetail';
import FocusedSkuDistribution from './FocusedSkuDistribution';

const propTypes = {
  getSkuDetails: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  onChannelSelect: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  categoryList: PropTypes.arrayOf(Object).isRequired,
  brandList: PropTypes.arrayOf(Object).isRequired,
  skuList: PropTypes.arrayOf(Object).isRequired,
  menu: PropTypes.arrayOf(Object).isRequired,
  focusedSKUDetails: PropTypes.func.isRequired,
  setCheckedSkus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  channelId: PropTypes.number,
  categoryId: PropTypes.number,
  brandId: PropTypes.number,
  showBrands: PropTypes.bool,
  skus: PropTypes.arrayOf(Object).isRequired,
  checkedSkus: PropTypes.arrayOf(Object).isRequired,
  basicDetail: PropTypes.objectOf(Object).isRequired,
};

const defaultProps = {
  channelId: 0,
  categoryId: 0,
  brandId: 0,
  showBrands: false,
};

const FocusedSkuDetailView = (props) => {
  const {
    onIconClick,
    menu,
    onChannelSelect,
    channelId,
    categoryList,
    brandList,
    showBrands,
    onCategorySelect,
    skuList,
    getSkuDetails,
    categoryId,
    brandId,
    handleSubmit,
    skus,
    basicDetail,
    setCheckedSkus,
    checkedSkus,
    focusedSKUDetails,
    loading,
    enableRouteSegmentation,
    getSKUList,
    permission,
  } = props;

  const getCounts = (param, paramId) => paramId !== 0 && focusedSKUDetails[param];

  const channelCount =
    getCounts('Channel', channelId) &&
    getCounts('Channel', channelId).find((d) => d.id === channelId) &&
    getCounts('Channel', channelId).find((d) => d.id === channelId).count;
  const categoryCount = getCounts('Category', channelId);
  const brandCount = getCounts('Brand', channelId);

  const getStatus = (id) =>
    focusedSKUDetails &&
    focusedSKUDetails.length !== 0 &&
    focusedSKUDetails.skuFamily.filter((d) => d === id) &&
    focusedSKUDetails.skuFamily.filter((d) => d === id).includes(id);
  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <div className='flex items-center justify-between m-0 flex-1'>
              <h2>{basicDetail.title}</h2>
              <div className='flex m-0'>
                {permission?.update&&<Button secondary iconBtnSmall onClick={() => onIconClick(EVENT_OPERATION.UPDATE)} iconName='pencil' />}
                {permission?.delete&&<Button secondary iconBtnSmall onClick={() => onIconClick(EVENT_OPERATION.DELETE)} iconName='trash' />}
                {(skus.length !== 0 || channelCount !== 0) && permission?.create && (
                  <Button
                    secondary
                    iconBtnSmall
                    onClick={() => onIconClick(EVENT_OPERATION.REPLICATE)}
                    iconName='copy'
                  />
                )}
              </div>
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content'>
        <div className='create-ui'>
          <div className='create-ui-inner tab-less-height'>
            <SBDDetailStyled>
              <BasicDetail
                basicDetail={basicDetail}
                enableRouteSegmentation={enableRouteSegmentation}
                options={menu.lineList}
              />
              <section className='sbd-bottom-wrap'>
                <div className='first'>
                  <CustomSelect
                    small
                    options={menu.channelList}
                    clearable={false}
                    className='custom-select'
                    placeholder='Select Channel'
                    getOptionLabel={({ id, title }) => (
                      <span>
                        {title}
                        {focusedSKUDetails.Channel && focusedSKUDetails.Channel.filter((d) => d.id === id)[0] && (
                          <Badge light label={focusedSKUDetails.Channel.filter((d) => d.id === id)[0].count} />
                        )}
                      </span>
                    )}
                    getOptionValue={({ id }) => id}
                    value={channelId !== 0 ? menu.channelList.filter((d) => d.id === channelId) : []}
                    onChange={(event) => onChannelSelect(event.id)}
                    isSearchable={false}
                  />
                  {channelId !== 0 && channelCount !== 0 && <Badge light label={channelCount || 0} />}
                  {categoryList.length === 0 && (
                    <span className='info-text'>←← Please select any channel to continue.</span>
                  )}
                </div>
                <div className='sbd-bottom-inner'>
                  <div className='sbd-panel'>
                    {categoryList.length !== 0 && (
                      <div className='sbd-panel-list'>
                        <h3>Category</h3>
                        <ul>
                          {categoryList.map((d) => (
                            <li
                              key={d.id}
                              onClick={() => onCategorySelect(d.id)}
                              className={d.id === categoryId ? 'active' : 'inActive'}
                            >
                              {d.title}{' '}
                              {categoryCount && categoryCount.find((catId) => catId.id === d.id) && (
                                <Badge
                                  light
                                  label={
                                    categoryCount.find((cat) => cat.id === d.id)
                                      ? categoryCount.find((cat) => cat.id === d.id).count
                                      : 0
                                  }
                                />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {categoryList.length !== 0 && !showBrands && (
                      <span className='info-text'>←← Please select any category to continue.</span>
                    )}
                  </div>
                  <div className='sbd-panel'>
                    {showBrands && (
                      <div className='sbd-panel-list'>
                        <h3>Brands</h3>
                        <ul>
                          {brandList.map((d) => (
                            <li
                              key={d.id}
                              onClick={() => getSkuDetails(d.id)}
                              className={`${d.id === brandId ? 'active' : 'inActive'} ${loading ? 'disabled-btn' : ''}`}
                            >
                              {d.title}
                              {brandCount && brandCount.find((brandId) => brandId.id === d.id) && (
                                <Badge light label={brandCount.find((brandId) => brandId.id === d.id).count || 0} />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {categoryList.length !== 0 && showBrands && skuList.length === 0 && (
                      <span className='info-text'>←← Please select brand to continue</span>
                    )}
                  </div>
                  {skuList.length !== 0 && (
                    <FocusedSkuDistribution
                      skuList={skuList}
                      checkedSkus={checkedSkus}
                      setCheckedSkus={setCheckedSkus}
                      getStatus={getStatus}
                      skus={skus}
                      handleSubmit={handleSubmit}
                      getSKUList={getSKUList}
                      permission={permission}
                    />
                  )}
                </div>
              </section>
            </SBDDetailStyled>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FocusedSkuDetailView.propTypes = propTypes;

FocusedSkuDetailView.defaultProps = defaultProps;

export default withErrorAndLoadingScreen(FocusedSkuDetailView);
