import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_MASTER_DATA } from '../../../common/GqlAPI';

const CALL_PLAN_SKU_FRAGMENT = gql`
  fragment CallPlanSKUListFragment on SKU {
    id
    brand {
      id
      title
    }
    title
    skuFamily{
      id
    }
    last_invoiced_date
    last_invoiced_quantity,
    images{
      image
      src
      thumbnail
      created_at
    }
    Rates {
      id
      priceDetails {
        rlp
        mrp
      }
    }
    ispromotionavailable
    promotion {
      id
      title
      scope
      billIssueStatus
    }
    purchase_order_history {
      date
      quantity
      sku_id
    }
    pfdtarget
    amount_details {
      netAmount
      discountAmount
    }
    call_order_value {
      netAmount
      discountAmount
    }
    quantity
    stockavailable
    balance
  }
`

const CALL_PLAN_OUTLET_DETAILS = gql`
 query getCallPlanDetail($callPlanId: Int!){
  getCallPlanDetail(callPlanId: $callPlanId) {
    outletBasic {
      id
      category
      categoryId
      channel
      call_roster
      pan_number
      lastInvoiceDate
      contact_info {
        name
        phoneNumber
      }
      title
      frequency
      dseName
      dseId
      image_url {
        image
        src
        thumbnail
        created_at
      }
    }
    outletAchievement {
      achievement
      target
    }
    callPlanBasic {
      callPlanScheduledTime
      planstatus
      distributorId
      distributor
      call_time {
        callStart
        callEnd
      }
      remarks
    }
    outStandingOverDue {
      creditLimit
      outStandingAmount
      RetailOutletLedgerBillDetails {
        bill_number
        bill_amount
        credit_days
        bill_outstanding_amount
      }
    }
    APGP {
      sbd
      ap
      gp
    }
    callHistory {
      lastSuccessfulCall {
        callTime
        callDate
      }
      lastCall {
        callNote
        callDate
        callType
        callTime
      }
      
      lastThereeSuccessfulCall {
        lastsuccessfulcalldate
        amount
        state
      }
    }
    ULPBLP {
      ulpo
      ubpo
    }
    brandEnrollment {
      id
      title
      is_enrolled
    }
    p3m_average
    avg_sales_per_bill
    validation_required
    tas_validation_settings {
      sbdcount
      nordervalidation
    }
    topskus {
      ...CallPlanSKUListFragment
    }
    recommendedSKUS {
      ...CallPlanSKUListFragment
    }
    SBDs {
      ...CallPlanSKUListFragment
    }
    FocusedSKUs {
      ...CallPlanSKUListFragment
    }
    SKUs {
      ...CallPlanSKUListFragment
    }
  }
} 

${CALL_PLAN_SKU_FRAGMENT}
`;

const SAVE_PLANS_FOR_TAS = graphql(
  gql`
  mutation saveplansfortas($p3mSKUs: [PFDDataInput]
      $brandSKUs: [PFDDataInput]
      $recommendedSKUs: [PFDDataInput]
      $pfdDate: String,
      $tas_user_call_plan_id: Int,
      $retailOutletId: Int!,
      $callTarget: Float!,
      $sbds: [PFDDataInput],
      $focusedSKUs: [PFDDataInput]
      ) {
      SavePlansForTAS(p3mSKUs:$p3mSKUs,
      brandSKUs:$brandSKUs,
      recommendedSKUs:$recommendedSKUs,
      pfdDate:$pfdDate,
      tas_user_call_plan_id:$tas_user_call_plan_id,
      retailOutletId: $retailOutletId,
      callTarget: $callTarget,
      sbds: $sbds,
      focusedSKUs: $focusedSKUs) {
    id
    callTarget
  }
}`,
  {
    props: ({ mutate }) => ({
      savePlansForTas: (variables) =>
        mutate({ variables })
    })
  }
)

const CALCULATE_PROMOTION = gql`
query calculatePromotion($input: [PromotionalSkusInput!], $categoryId: Int!, $distributorId: Int,
   $callPlanId: Int
   ){
 calculatePromotionDiscounts(input: $input, categoryId: $categoryId, distributorId: $distributorId,
   callPlanId:$callPlanId
   ) {
   normalPromotionAppilied {
     netAmount
     promotions {
       freeSkus {
         selected
         quantity
         maxQuantity
         skuId
         title
         skuBatchRateId
          rateDetails {
            rlp
          }
          familyId
       }
       id
       promotionType
       promotionAmount
     }
     skuId
   }
}
}
`;

const CALCULATE_ALL_PROMOTION = gql`
query calculateAllPromotionDiscounts($input: [PromotionalSkusInput!], $categoryId: Int!, $distributorId: Int, $callPlanId: Int!){
  calculateAllPromotionDiscounts(input: $input, categoryId: $categoryId, distributorId: $distributorId, callPlanId: $callPlanId ) {
    orders {
      skuBatchRateId
      skuId
      familyId
      distributorId
      rateDetails {
        rlp
        netPrice
        rlpWithVat
        netPriceWithVat
      }
      quantity
      promotions {
        id
        promotionScope
        freeSkus {
          selected
          quantity
          maxQuantity
          skuId
          title
          skuBatchRateId
          rateDetails {
            rlp
          }
          familyId
        }
        title
        promotionType
        promotionAmount
      }
      amountDetails {
        subTotal
        netAmount
        taxAmount
        grossAmount
        billDiscount
        taxableAmount
        topUpDiscount
        tradeDiscount
        discountAmount
        netSaving : discountAmount
        promotionDiscount
      }
      promotionStatus
      freeSku
    }
    callOrderValue {
      subTotal
      netAmount
      taxAmount
      grossAmount
      billDiscount
      taxableAmount
      topUpDiscount
      tradeDiscount
      discountAmount
      promotionDiscount
    }
    callTarget
}
}
`;

const GET_PROMOTIONAL_SKUS = gql`
query getpromotionalskus($promotionId: Int!,$distributorId: Int!) {
  getPromotionSkus(promotionId: $promotionId,distributorId: $distributorId) {
    id
    title
    stockavailable
    balance
    brand{
      id
      title
    }
    images{
      image
      src
      thumbnail
    }
    skuFamily{
      id
    }
    Rates {
      priceDetails{
        rlp
        mrp
      }
      skuId
      id
      mrp
    }
  }
}
`;
const SAVE_TAS_FOR_UNSUCCESSFUL_CALLS = graphql(
  gql`
  mutation SaveTASUnsuccessfulCalls (
  $input: TasUserCallInput!) {
  saveTASUnsuccessfulCalls(input: $input)

}`,
  {
    props: ({ mutate }) => ({
      SaveTASUnsuccessfulCalls: (variables) =>
        mutate({ variables })
    })
  }
)

const SAVE_PLANS_FOR_SUCCESSFUL_CALLS = graphql(
  gql`
  mutation saveTasSuccessfulCalls ( $input: TasUserCallInput!) {
  saveTASSuccessfulCalls(input: $input)
}`,
  {
    props: ({ mutate }) => ({
      saveTasSuccessfulCalls: (variables) =>
        mutate({ variables })
    })
  }
)


const query = {
  getCallPlanOutletDetails: CALL_PLAN_OUTLET_DETAILS,
  calculatePromotion: CALCULATE_PROMOTION,
  getpromotionalskus: GET_PROMOTIONAL_SKUS,
  calculateAllPromotionDiscounts: CALCULATE_ALL_PROMOTION,
  getMasterData: GET_MASTER_DATA,
}

const mutation = {
  savePlansForTas: SAVE_PLANS_FOR_TAS,
  SaveTASUnsuccessfulCalls: SAVE_TAS_FOR_UNSUCCESSFUL_CALLS,
  saveTasSuccessfulCalls: SAVE_PLANS_FOR_SUCCESSFUL_CALLS,
};

export {
  query, mutation, SAVE_PLANS_FOR_TAS, SAVE_TAS_FOR_UNSUCCESSFUL_CALLS, SAVE_PLANS_FOR_SUCCESSFUL_CALLS
}
