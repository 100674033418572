import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { GRN_FRAGMENT } from '../../grn/API';
import { GRN_LINE_FRAGMENT } from '../../grn/createGrn/API';
import {
  GET_DISTRIBUTORS_SERVICES, GET_SKU_WITH_BATCH, GET_VENDORS, LIST_MASTER_CONFIGURATION
} from '../../../common/GqlAPI';

const GET_UPDATED_GRN_DETAIL = gql`
query getGrnDetail($invoiceNumber: String!,$distributorId:Int!) {
  findGRNInvoice(invoiceNumber: $invoiceNumber,distributorId:$distributorId) {
      ...GrnFragment
      lines {
        ...GrnLine
        sellable: updatedSellable
        damages: updatedDamages
        shortages: updatedShortages
        actualdamages: damages
        actualshortages: shortages
      }
    }
  }
  ${GRN_FRAGMENT}
  ${GRN_LINE_FRAGMENT}
`;

const CREATE_PRN = graphql(
  gql`
    mutation ($input: PRNInput!) {
      createPurchaseReturn(input: $input) {
        id
        prnInvoiceNumber
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createPrn: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_GRN_INVOICE_NUMBER_LIST = gql`
  query getInvoiceNumberList($invoiceString: String!, $distributorId: Int!) {
    searchGRNInvoiceNumber(invoiceString: $invoiceString, distributorId: $distributorId) {
      invoiceNumbers
      matched
    }
  }
`;

const CHECK_SKU_STOCK = gql`
  query checkSkuStock($skuId: Int!, $distributorId: Int!, $quantity: Int!) {
    checkSkuStock(skuId: $skuId, distributorId: $distributorId, quantity: $quantity)
  }
`;

const CHECK_SKU_STOCK_WITH_BATCH = gql`
  query checkSkuStockWithBatch($skuId:Int!, $distributorId:Int!, $skuBatchId: Int!, $sellable: Int!, $damages: Int, $expiry: Int, $shortages: Int, $fromUI: Boolean){
    checkSkuStockWithBatch(skuId: $skuId, distributorId: $distributorId, skuBatchId: $skuBatchId, sellable: $sellable, damages: $damages, expiry: $expiry, shortages: $shortages, fromUI: $fromUI){
      sellable
      expiry
      damage
      shortage
    }
  }
`;

const GET_SKU_BATCH_DETAILS = gql`
  query getSKUBatchDetail($distributorId: Int!, $skuId: Int!) {
    getSkuBatchDetails(distributorId: $distributorId, skuId: $skuId) {
      id
      batchName
      manufactureDate
      expiryDate
      quantity
      dlp
      discount
      sellableStock
      damagedStock
      expiredStock
      shortageStock
    }
  }
`;

const query = {
  getVendors: GET_VENDORS,
  getSkus: GET_SKU_WITH_BATCH,
  getDistributors: GET_DISTRIBUTORS_SERVICES,
  getUpdatedGrnDetail: GET_UPDATED_GRN_DETAIL,
  getSKUBatchDetail: GET_SKU_BATCH_DETAILS,
  checkSkuStockWithBatch: CHECK_SKU_STOCK_WITH_BATCH,
  listMasterConfiguration: LIST_MASTER_CONFIGURATION,
};

const mutation = {
  createPrn: CREATE_PRN,
};

export {
  CREATE_PRN,
  CHECK_SKU_STOCK_WITH_BATCH,
  GET_GRN_INVOICE_NUMBER_LIST,
};

export { query, mutation };
