import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import PrnView from './View';
import { breadCrumb, dataMapper } from './config';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { DOMAIN, WIDGETS } from '../../../../data/enums/config';
import { handlePrint } from '../../../common/HelperFunctions';
import withAlert from '../../../../utils/composition/withAlert';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getPrnDetails: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {};

class PrnDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: dataMapper({}),
      actionWidgets: {
        [WIDGETS.PRINT]: false,
      },
      exciseInLine: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getList();
    this.getlistMasterConfiguration();
  }

  getlistMasterConfiguration = () => {
    const { listMasterConfiguration, displayAlert } = this.props;
    listMasterConfiguration(
      { type: 'GRN_CONFIG' },
      {
        handleSuccess: res => {
          const grnValidationConfig = res.data.listMasterConfiguration.filter(a => a.type === 'GRN_CONFIG');
          this.setState({
            exciseInLine: grnValidationConfig.length > 0 && grnValidationConfig[0].configuration?.exciseInLine,
          });
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  getList = () => {
    const { actionWidgets } = this.state;
    const { getPrnDetails, displayAlert, match } = this.props;
    /** after api supports details fetch from prnInvoiceNumber  * */
    // prnInvoiceNumber: voucherDecoder(match.params.id),
    getPrnDetails(
      {
        prnId: parseInt(match.params.id || '', 10),
      },
      {
        handleSuccess: response => {
          const { prnDetails = {} } = response.data;
          const modifiedData = dataMapper(prnDetails);
          actionWidgets[WIDGETS.PRINT] = modifiedData.Distributor.servicesUsed.billing || false;
          this.setState({ data: modifiedData });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  handlePrintClick = () => {
    const { data } = this.state;
    handlePrint({
      module: data.Distributor.servicesUsed.billing.versionCode===2?DOMAIN.DBS_PURCHASE_RETURN:DOMAIN.PURCHASE_RETURN,
      invoiceNumber: data.prnInvoiceNumber,
      distributorServices: data.Distributor.servicesUsed,
    });
  };
  
  getActionConfig = type => ({ handler: this.handlePrintClick });

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  render() {
    const { data, actionWidgets, exciseInLine} = this.state;

    return (
      <Fragment>
        <PrnView 
          data={data}
          title={data.prnInvoiceNumber}
          actionWidgets={actionWidgets}
          getActionConfig={this.getActionConfig}
          breadCrumb={breadCrumb}
          exciseInLine={exciseInLine}
          />
      </Fragment>
    );
  }
}

PrnDetails.propTypes = propTypes;

PrnDetails.defaultProps = defaultProps;

export default withAlert()(PrnDetails);
