import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  levels: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  data: {
    title: '',
  },
  enableErrorDisplay: false,
  handleInputChange: () => null,
  title: '',
  type: '',
};

const WarehouseForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, cardTitle, type, levels } = props;
  return (
    <Form>
      {cardTitle !== levels[0].title && (
        <Input
          ref={(ref) => (refsObj.title = ref)}
          value={data.parent ? data.parent.title : ''}
          name='title'
          type='text'
          placeholder='Enter Warehouse'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event, 'parent')}
          labelContent='Parent'
          disabled={type === EVENT_OPERATION.READ || cardTitle !== levels[0].title}
        />
      )}
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Enter Title'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Title'
        disabled={type === EVENT_OPERATION.READ}
      />
      <Input
        ref={(ref) => (refsObj.levelIndication = ref)}
        value={data.levelIndication}
        name='levelIndication'
        type='text'
        placeholder='Enter Level of Indication'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Level of Indication (Letters or Numbers)'
        disabled={type === EVENT_OPERATION.READ}
      />
    </Form>
  );
};

WarehouseForm.propTypes = propTypes;

WarehouseForm.defaultProps = defaultProps;

const ComposedWarehouseForm = withLoading(WarehouseForm);

export default ComposedWarehouseForm;
