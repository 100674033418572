import React, { Fragment } from 'react';
import { Input } from '../../../../components';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { Button, tableData } from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';

const labelMappings = {
  brand_sku: 'brand_sku',
  p3m: 'p3m',
  lastMonth: 'lastMonth',
  thisMonthTarget: 'thisMonthTarget',
  unallocatedTarget: 'unallocatedTarget',
  action: 'action',
  coverage:'coverage'
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.brand_sku,
    show: true,
    dynamicTitle: true,
  },
  {
    id: 2,
    label: labelMappings.p3m,
    title: 'P3M Average Sales',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.lastMonth,
    title: 'Last Month Sales',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.thisMonthTarget,
    title: 'This Month Target',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.unallocatedTarget,
    title: 'Unallocated Target',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.coverage,
    title: 'Coverage',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.action,
    title: '',
    show: true,
  },
];

const returnTableBody = (data, onRowClick, onIconClick, onCancelClick, onSaveClick, button, isAccordionOpen,
  permission) => {
  const table = {};
  table[labelMappings.brand_sku] = (
    <td onClick={() => onRowClick(data)} key={`subDTarget-${data.brand_id}-skuId`}>
      <span>{(data.title) || ''}</span>
    </td>
  );
  table[labelMappings.p3m] = (
    <td onClick={() => onRowClick(data)} key={`subDTarget-${data.brand_id}-title`}>
      {fixedFloatAndCommas(data.lastthreemonthtarget) || 0}
    </td>
  );
  table[labelMappings.lastMonth] = (
    <td onClick={() => onRowClick(data)} key={`subDTarget-${data.brand_id}-lastMonth`}>
      {fixedFloatAndCommas(data.lastmonthtarget)||0}
    </td>
  );
  table[labelMappings.thisMonthTarget] = (
    <td onClick={() => onRowClick(data)} key={`subDTarget-${data.brand_id}-thisMonthTarget`}>
      {fixedFloatAndCommas(data.target)||0}
    </td>
  );
  table[labelMappings.unallocatedTarget] = (
    <td onClick={() => onRowClick(data)} key={`subDTarget-${data.brand_id}-unallocatedTarget`}>
      {fixedFloatAndCommas(data.unallocatedTarget)||0}
    </td>
  );
  table[labelMappings.coverage] = (
    <td onClick={() => onRowClick(data)} key={`subDTarget-${data.brand_id}-coverage`}>
      {fixedFloatAndCommas(data.coverage)||0}
    </td>
  );
  table[detailLabelMappings.action] = (
    <td key={`${data.brand_id}-action`} className='text-right' onClick={(e) => e.stopPropagation()}>
      <>
        { (permission.update&&isAccordionOpen && data.brand_id===(button.buttonData && button.buttonData.brand_id)) && ((button.buttonType===EVENT_OPERATION.READ)?(
          <Button
            secondary
            iconName='pencil'
            small
            title={EVENT_OPERATION.EDIT.toLocaleLowerCase()}
            onClick={() => onIconClick(EVENT_OPERATION.UPDATE,data)}
          />
        ):((data.brand_id===(button.buttonData && button.buttonData.brand_id)) ?(
          <div>
            <Button
              secondary
              iconBtnSmall
              iconName='cross'
              onClick={() => onCancelClick(data)}
            />
            <Button primary small title='Save Changes' onClick={() => onSaveClick()} />
          </div>
        ):(
          null)
        ))}
      </>
    </td>
  );

  return table;
};

const TableHeader = (sku) => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label,{ 1: `${sku ? 'Brand / SKU': 'Brand / SKU Family'}` }) : null))}</tr>
  </thead>
);

const TableBody = ({
  data, onRowClick, onIconClick, onCancelClick, onSaveClick, button, isAccordionOpen,
  permission
}) => {
  const tableBody = returnTableBody(data, onRowClick, onIconClick, onCancelClick, onSaveClick, button, isAccordionOpen,
    permission);
  return <Fragment key={data.skuId}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

const detailLabelMappings = {
  brandSku: 'brandSku',
  p3m: 'p3m',
  lastMonth:'lastMonth',
  thisMonthTarget:'thisMonthTarget',
  unallocatedTarget:'unallocatedTarget',
  action:'action',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.brandSku,
    title: 'Brand/SKU',
    show: true,
    align: true,
  },
  {
    id: 2,
    label: detailLabelMappings.p3m,
    title: 'P3M',
    show: true,
    align: true,
  },
  {
    id: 2,
    label: detailLabelMappings.lastMonth,
    title: 'Last Month',
    show: true,
    align: true,
  },
  {
    id: 2,
    label: detailLabelMappings.thisMonthTarget,
    title: 'This Month Target',
    show: true,
    align: true,
  },
  {
    id: 2,
    label: detailLabelMappings.unallocatedTarget,
    title: 'Unallocated Target',
    show: true,
    align: true,
  },
  {
    id: 2,
    label: detailLabelMappings.action,
    title: '',
    show: true,
    align: true,
  },
];

const returnDetailTableBody = (data, onInputChange, index, button) => {
  const table = {};
  table[detailLabelMappings.brandSku] = (
    <td key={`${data.brand_id}-id`}>
      {data.sku_family_title || data.sku_title}
    </td>
  );
  table[detailLabelMappings.p3m] = (
    <td key={`${data.brand_id}-id`}>
      {fixedFloatAndCommas(data.lastthreemonthtarget)||0}
    </td>
  );
  table[detailLabelMappings.lastMonth] = (
    <td key={`${data.brand_id}-id`}>
      {fixedFloatAndCommas(data.lastmonthtarget)||0}
    </td>
  );
  table[detailLabelMappings.thisMonthTarget] = (
    <td key={`${data.brand_id}-p3m`} className='input-section'>
      <Input
        value ={data.currenttarget}
        name='currenttarget'
        type='number'
        onChange={(event) => onInputChange(event,'currenttarget',['details',index,'currenttarget'])}
        disabled={button.buttonType!==EVENT_OPERATION.UPDATE}
      />
    </td>
  );
  table[detailLabelMappings.unallocatedTarget] = (
    <td />
  );
  table[detailLabelMappings.action] = (
    <td/>
  );
  return table;
};

const DetailTableBody = ({ data, onInputChange, index, button,permission }) => {
  const tableBody = returnDetailTableBody(data, onInputChange, index, button,permission);

  return <Fragment>{detailsLabelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

export {
  TableHeader, TableBody, DetailTableBody, detailsLabelConfig
}
