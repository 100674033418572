import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { DialogFormWrapper } from '../../../../common';
import { ORDER_FULFILMENT } from '../../../../../data/enums/Route';
import Table from './table';
import CustomerDetail from './customerDetail';
import { PanelStyled } from '../../../../common/configuration';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import { handleFormSubmit } from '../../../../../utils/crudResponseProcessor';
import { crudSuccess as crudRequestConfig, detailMapper, breadCrumb, formConfig } from './config';
import { clone } from '../../../../../utils/arrayProcessor';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { getPermissionForOrderFulfilment } from '../../../../base/permission';
import { getUserRole } from '../../../../../data/services';
import { USER_ROLE } from '../../../../../data/enums';
import SalesInvoiceStyled from './SalesInvoiceStyled';
import withLoading from '../../../../../utils/composition/withLoading';
import { handlePrint, headerLabelConfig } from '../../../../common/HelperFunctions';
import { PanelCard,BreadCrumb,Button } from '../../../../../v4/components';
import { USER_ROLE_TITLE } from '../../../../../data/enums/UserRole';
import { DOMAIN } from '../../../../../data/enums/config';
import  { CustDet } from '../../../../../views/sales/orderProcessing/received/salesInvoice/customerDetail/CustomerDetail';

const propTypes = {
  breadCrumb: PropTypes.array,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  invoiceOrders: PropTypes.func.isRequired,
  checkLedgerStatus: PropTypes.func,
};

const defaultProps = {
  breadCrumb: [],
  serverResponseWaiting: false,
  checkLedgerStatus: () => null,
};

class SalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: detailMapper({}),
      enableErrorDisplay: false,
      permission: { BILLING: true, INVOICE: true },
      ledgerStatus: {},
      dialog: {
        type: '',
        element: '',
      },
      credit:{days: false, limit: false}
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.invoiceOrders,
    };
    this.onCRUDSuccess = this.responseProcessor(this.handleInvoiceSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    // this.loggedInUserPermission = getPermissionForSalesInvoice();
    this.permission = getPermissionForOrderFulfilment();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const data = detailMapper(JSON.parse(localStorage.getItem('activeOrder'))) || {};

    this.setState({ data }, () => this.derivePermission());
  };

  derivePermission = () => {
    const { data } = this.state;
    const billingStatus = data.Distributor.servicesUsed.billing.status || false;
    let invoiceStatus = this.permission.update;
    if (billingStatus && this.permission.update) {
      invoiceStatus = !(getUserRole() === USER_ROLE.DISTRIBUTOR_ADMIN);
    }
    this.setState({
      permission: {
        BILLING: billingStatus,
        INVOICE: invoiceStatus,
      },
    });
  };

  invoiceSubmit = (customerId) => {
    let outstandingAmount = 0;
    let creditDays = 0;
    let creditStatus={};
    let status = false;
   
    const { ledgerStatus, data } = this.state;
    const { getOutstandingValue } = this.props;
    const updatedData = clone(data);
    updatedData.amountDetails = this.getAmountDetails();
    updatedData.customerDetails = this.getCustomerDetails();

    getOutstandingValue(
      {
        customerId,
        distributorId:data.Distributor.id,
      },
      {
        handleSuccess: res => {
          // eslint-disable-next-line max-len
              if (res?.data) {
                this.setState({
                  credit: res?.data?.getOutstandingValue?.settings?.credit
                })
                outstandingAmount= res?.data?.getOutstandingValue?.outstanding_amount; 
                creditDays= res?.data?.getOutstandingValue?.credit_days; 
                creditStatus = res?.data?.getOutstandingValue?.settings?.credit;
                if(((creditDays - updatedData.customerDetails.Customer.creditDay  > 0) && updatedData.customerDetails.Customer.creditDay) && outstandingAmount < -5){
                  status = true;
               }
              }
        
              if (
                status &&
                updatedData.customerDetails.Customer.creditLimit !== 0 &&
                updatedData.customerDetails.Customer.creditLimit <=
                  updatedData.amountDetails.total + -1 * outstandingAmount
              ) {
                this.setState({
                  dialog: {
                    type: EVENT_OPERATION.UPDATE,
                    element: {
                      reason: 'Credit limit/days has already exceeded.',
                      warning: `${(creditStatus?.limit||creditStatus?.days)?'':'Do you want to continue invoicing?'}`
                    },
                  },
                });
              } else if (status) {
                this.setState({
                  dialog: {
                    type: EVENT_OPERATION.UPDATE,
                    element: {
                      reason: 'Credit days has already exceeded.',
                      warning: `${creditStatus?.days?'':'Do you want to continue invoicing?'}`
                    },
                  },
                });
              } else if (
                updatedData.customerDetails.Customer.creditLimit !== 0 &&
                updatedData.customerDetails.Customer.creditLimit <=
                  updatedData.amountDetails.total + -1 * outstandingAmount
              ) {
                this.setState({
                  dialog: {
                    type: EVENT_OPERATION.UPDATE,
                    element: {
                      reason: 'Credit limit has already exceeded.',
                      warning: `${creditStatus?.limit?'':'Do you want to continue invoicing?'}`
                    },
                  },
                });
              } else {
                this.createInvoice();
              }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
          outstandingAmount= updatedData.customerDetails.Customer.outstandingAmount;
          status= ledgerStatus.status;
          this.createInvoice();
        },
      },
    );  
  };

  ledgerStatusCheck = (id, customerId) => {
    const { checkLedgerStatus, displayAlert } = this.props;
    checkLedgerStatus(
      {
        customerId: id || null,
      },
      {
        handleSuccess: response => {
          const ledgerStatus = response.data && response.data.checkLedgerStatus;
          this.setState(
            {
              ledgerStatus,
            },
            () => {
              this.invoiceSubmit(customerId);
            },
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleSubmit = () => {
    const ledger = this.getCustomerDetails();
    const { permission } = this.state;
    const valid = this.getValidationStatus();
    if (valid) {
      if (permission.BILLING && !ledger.isCash) {
        this.ledgerStatusCheck(ledger.Customer.id,ledger.Customer.id);
      } else {
        this.createInvoice();
      }
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  // handleSubmit = () => {
  //   const valid = this.getValidationStatus();
  //   if (valid) {
  //     this.createInvoice();
  //   } else {
  //     this.setState({ enableErrorDisplay: true });
  //   }
  // };

  handleCancel = () => {
    this.directToMainPage();
  };

  getValidationStatus = () => {
    const detailsStatus = this.getCustomerDetailsValidation();
    const tableStatus = this.getAmountDetailsValidation();

    return detailsStatus && tableStatus;
  };

  createInvoice = () => {
    const { data } = this.state;
    const updatedData = clone(data);
    updatedData.amountDetails = this.getAmountDetails();
    updatedData.customerDetails = this.getCustomerDetails();
    this.onFormSubmit(EVENT_OPERATION.CREATE, updatedData);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  responseProcessor = callBack => {
    const onCRUDSuccess = type => response => {
      callBack(response, type);
    };

    return onCRUDSuccess;
  };

  handleInvoiceSuccess = (response, type) => {
    const { permission, data } = this.state;
    const { displayAlert } = this.props;
    const domain = response.isAbbreviatedInvoice ? DOMAIN.ABBREVIATED_INVOICE : DOMAIN.SALES;
    if (permission.BILLING) {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      setTimeout(() => {
        handlePrint(
          {
            distributorServices: data.Distributor.servicesUsed,
            invoiceNumber: response.invoiceNumber,
            isAbbreviatedInvoice: response.isAbbreviatedInvoice,
            firstCopy: true,
            module: data.Distributor.servicesUsed.billing.versionCode === 2 ? DOMAIN.DBS_SALES : domain,
          },
          this.directToMainPage,
        );
      }, 800);
    } else {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    }
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  directToMainPage = () => {
    /** direct to Order Fulfilment page */
    const { history } = this.props;
    history.push(`/${ORDER_FULFILMENT}`);
  };

  handleDialogSubmit = () => {
    const { dialog, credit } =this.state;
    if((credit?.limit || credit?.days) && dialog?.element?.reason === 'Credit limit/days has already exceeded.'){
      this.resetDialog();
    }else if(credit?.limit && dialog?.element?.reason==='Credit limit has already exceeded.'){
      this.resetDialog();
    }else if(credit?.days && dialog?.element?.reason==='Credit days has already exceeded.'){
      this.resetDialog();
    }else{
      this.createInvoice();
    }
  }

  render() {
    const { data, dialog, permission, enableErrorDisplay } = this.state;
    const { type, element } = dialog;
    const { loading } = this.props;
    /** todo: render according to due invoice page design */
    return (
      <SalesInvoiceStyled>
        {type && (
          <DialogFormWrapper
            onDialogSubmit={this.handleDialogSubmit}
            onDialogCancel={this.resetDialog}
            formConfig={formConfig[type]}
            type={type}
            formTitle="Warning"
            renderDialog={() => <>{type === EVENT_OPERATION.UPDATE && element && `${element.reason} ${element.warning}`}</>}
          />
        )}
        <div className="section-header border-b">
          <PanelStyled>
            <BreadCrumb list={breadCrumb} />
            <div className="invoice-view-wrap">
              <h2 className="invoice-title">
                {data.RetailOutlet.title}
                <span>
                  <a href={`/sales/outlet/details/${data.RetailOutlet.id}`} target="_blank">
                    <img src="/image/icons/newTab.svg" />
                  </a>
                </span>
              </h2>
              <div className="invoice-inner">
                <Button small secondary disabled={loading} onClick={() => this.handleCancel()}>
                  Cancel
                </Button>
                {this.permission.update && (
                  <Button small primary disabled={loading} onClick={() => this.handleSubmit()}>
                    Invoice
                  </Button>
                )}
              </div>
            </div>
          </PanelStyled>
        </div>
        <div className="section-content invoice">
          <div className="invoice-stats">
            <PanelCard cardTitle="Details">
              <div className="customer-info">
                <Row>
                  <Col md={3}>
                    <div className="info">
                      <label>Outlet ID</label>
                      <span>{data.RetailOutlet.id}</span>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="info">
                      <label>Category</label>
                      <span>{data.RetailOutlet.Category.title}</span>
                    </div>
                  </Col>
                  {/* <Col md={3}>
                        <div className="info">
                          <label>Address</label>
                          <span>{data.RetailOutlet.address}</span>
                        </div>
                      </Col> */}
                  <Col md={3}>
                    <div className="info">
                      <label>PAN</label>

                      <span>{data.RetailOutlet.panNumber}</span>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="info">
                      <label>{headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'}</label>
                      <span>{data.User.fullName}</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <CustomerDetail
                loading={loading}
                outletId={data.RetailOutlet.id}
                distributorId={data.Distributor.id}
                townId={data.RetailOutlet.townId}
                permission={permission}
                enableErrorDisplay={enableErrorDisplay}
                getDetails={childMethod => (this.getCustomerDetails = childMethod)}
                getStatus={childMethod => (this.getCustomerDetailsValidation = childMethod)}
              />
            </PanelCard>
            <PanelCard cardTitle="SKU">
              <div className="invoice-panel-wrap">
                <div className="invoice-table-wrap">
                  <Table
                    data={data.selectedOrders}
                    amountDetails={data.callOrderValue || {}}
                    enableErrorDisplay={enableErrorDisplay}
                    getDetails={childMethod => (this.getAmountDetails = childMethod)}
                    getStatus={childMethod => (this.getAmountDetailsValidation = childMethod)}
                  />
                </div>
              </div>
            </PanelCard>
          </div>
        </div>
      </SalesInvoiceStyled>
    );
  }
}


SalesInvoice.defaultProps = defaultProps;

export default withLoading(withAlert()(SalesInvoice));
