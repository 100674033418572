import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import LEDGERS from '../../../../../data/enums/Ledgers';
import {
  Form, Input, SingleDatePicker
} from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import CustomAutoComplete from '../../../../../components/CustomAutoComplete';
import { remarksList } from './config';
import { getTodaysDate } from '../../../../../utils/date';
import CustomSelectWithBtn from '../../../../../components/CustomSelectWithBtn';
import { Icon, CheckBox } from '../../../../../v4/components';

const propTypes = {
  subDUser: PropTypes.bool,
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  outletList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
  customerList: PropTypes.instanceOf(Array),
  invoiceNumberList: PropTypes.instanceOf(Array),
  srnRouteLineList: PropTypes.instanceOf(Array),
  distributorServices: PropTypes.instanceOf(Object),
  invoiceNumberStatus: PropTypes.bool,
  loadingInvoiceStatus: PropTypes.bool,
  handleIconClick: PropTypes.func,
  pending: PropTypes.bool,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  subDUser: false,
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  outletList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  handleInputChange: () => null,
  customerList: [],
  invoiceNumberList: [],
  distributorServices: {},
  invoiceNumberStatus: false,
  loadingInvoiceStatus: false,
  srnRouteLineList: [],
  handleIconClick: () => null,
  pending: false,
};

const SrnSkuForm = ({ ...props }) => {
  const {
    data,
    update,
    subDUser,
    refsObj,
    outletList,
    customerList,
    distributorList,
    handleInputChange,
    invoiceNumberList,
    enableErrorDisplay,
    distributorServices,
    handleDropDownChange,
    invoiceNumberStatus,
    loadingInvoiceStatus,
    srnRouteLineList,
    pending,
    handleIconClick,
  } = props;

  const onInvoiceNumberSelection = (formattedValue, name) => {
    handleInputChange({ formattedValue, target: { name } });
  };

  const distributorSpecificCustomerList = customerList.filter(
    (customer) => customer.distributorId === data.Distributor.id || customer.id === LEDGERS.CASH.id,
  );
  const cashLedger = [
    {
      id: LEDGERS.CASH.ledger_id,
      name: LEDGERS.CASH.name,
    },
  ];

  const requiredCustomerList =
    data.Customer.id === LEDGERS.CASH.ledger_id ? cashLedger : distributorSpecificCustomerList;
  return (
    <Form className='grn-form'>
      <Row>
        <Col md={12}>
          <Row>
            {!subDUser && (
              <Fragment>
                <Col className='mb-24' md={8}>
                  <CustomSelect
                    enableValidation
                    labelContent='Distributor'
                    options={distributorList}
                    className='custom-select'
                    placeholder='Select Distributors'
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    enableErrorDisplay={enableErrorDisplay}
                    ref={(ref) => (refsObj.distributorId = ref)}
                    onChange={(event) => handleDropDownChange(event.id, ['Distributor', 'id'], 'distributor', event)}
                    value={distributorList.filter(({ id }) => id === data.Distributor.id)}
                  />
                </Col>
              </Fragment>
            )}
          </Row>
          <Row>
            {pending ? (
              <Input name='salesInvoiceNumber' value={data.salesInvoiceNumber} labelContent='Sales Invoice Number' />
            ) : (
              <Col className='mb-24' md={3}>
                <div className='grn-label-text'>
                  <CustomAutoComplete
                    name='salesInvoiceNumber'
                    onChange={handleInputChange}
                    dropDownList={invoiceNumberList}
                    labelContent='Sales Invoice Number'
                    dropDownValueKey={null}
                    dropDownDisplayKey={null}
                    disabled={loadingInvoiceStatus}
                    onDropDownSelection={onInvoiceNumberSelection}
                  />
                  {invoiceNumberStatus && (
                    <div className='success-label'>
                      <Icon iconName='check-circle-full' />
                      <span>Valid Number</span>
                    </div>
                  )}
                </div>
              </Col>
            )}
            {!distributorServices.billing.status && !pending && (
              <Col className='mb-24' md={3}>
                <Input
                  name='salesReturnInvoiceNumber'
                  type='text'
                  value={data.salesReturnInvoiceNumber}
                  labelContent='Sales Return Invoice Number'
                  placeholder='Sales Return Invoice Number'
                  onChange={(event) => handleInputChange(event)}
                />
              </Col>
            )}
            {/* <Col className="mb-24" md={6}>
                <CheckBox
                  labelText="Cash"
                  checked={data.cashStatus}
                  onCheckBoxClick={e => handleInputChange({ formattedValue: e.target.checked, target: { name: 'cashStatus' } })}
                />
              </Col> */}
            <Col className='mb-24' md={3}>
              <CustomSelect
                enableValidation
                options={outletList}
                labelContent='Outlet'
                className='custom-select'
                placeholder='Select Outlet'
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                ref={(ref) => (refsObj.outletId = ref)}
                enableErrorDisplay={enableErrorDisplay}
                disabled={pending || update.type === EVENT_OPERATION.APPROVE || invoiceNumberList.length > 0}
                onChange={(event) => handleDropDownChange(event.id, ['RetailOutlet', 'id'], 'outlet')}
                value={outletList.filter(({ id }) => id === data.RetailOutlet.id) || []}
              />
            </Col>
            {srnRouteLineList && srnRouteLineList.length > 0 && (
              <Fragment>
                {srnRouteLineList.length === 1 ? (
                  <>
                    <Col className='mb-24' md={3}>
                      <Input
                        disabled
                        name='line'
                        type='text'
                        value={srnRouteLineList[0] && srnRouteLineList[0].title}
                        labelContent='Route Segmentation'
                        placeholder='Route Segmentation'
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className='mb-24' md={3}>
                      <CustomSelect
                        enableValidation
                        options={srnRouteLineList}
                        labelContent='Route Segmentation'
                        className='custom-select'
                        placeholder='Select Line'
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                        ref={(ref) => (refsObj.lineId = ref)}
                        enableErrorDisplay={enableErrorDisplay}
                        disabled={update.type === EVENT_OPERATION.APPROVE || invoiceNumberList.length > 0}
                        onChange={(event) => handleDropDownChange(event.id, ['lineId'])}
                        value={srnRouteLineList.filter(({ id }) => id === data.lineId) || []}
                      />
                    </Col>
                  </>
                )}
              </Fragment>
            )}
          </Row>
          <Row>
            <Col className='mb-24' md={3}>
              <CustomSelectWithBtn
                name='customerId'
                options={distributorSpecificCustomerList}
                placeholder='Select Customer Ledger'
                labelContent='Select Customer Ledger'
                enableErrorDisplay={distributorServices.billing.status ? enableErrorDisplay : false}
                onChange={(event) => handleDropDownChange(event.id, ['Customer', 'id'], 'customer')}
                value={distributorSpecificCustomerList.filter(({ id }) => id === data.Customer.id)}
                handleIconClick={handleIconClick}
                handleInputChange={handleInputChange}
                data={data}
                disabled={!data.RetailOutlet.id}
                enableValidation
                ref={distributorServices.billing.status ? (ref) => (refsObj.ledgerId = ref) : null}
              />
            </Col>
            <Col className='mb-24' md={3}>
              <Input
                disabled
                name='customerName'
                type='text'
                value={data.customerName}
                labelContent='Bill Name'
                placeholder='Bill Name'
              />
            </Col>
            <Col className='mb-24' md={3}>
              <Input
                disabled
                name='address'
                type='text'
                value={data.Customer.address}
                labelContent='Address'
                placeholder='Address'
              />
            </Col>
            <Col className='mb-24' md={3}>
              <Input
                disabled
                name='panNumber'
                type='number'
                value={data.Customer.panNumber}
                labelContent='PAN'
                placeholder='PAN Number'
              />
            </Col>
            <Col className='mb-24' md={6}>
              <CustomSelect
                ref={(ref) => (refsObj.reason = ref)}
                enableValidation
                labelContent='Remarks'
                name='reason'
                placeholder='Add Remarks'
                className='custom-select'
                options={remarksList}
                getOptionValue={({ remark }) => remark}
                getOptionLabel={({ remark }) => remark}
                onChange={(event) => handleDropDownChange(event.remark, ['reason'])}
                enableErrorDisplay={enableErrorDisplay}
                value={remarksList.filter(({ remark }) => remark === data.reason)}
                isSearchable={false}
              />
            </Col>
            <Col className='m-0' md={3}>
              <div className='single-date-picker'>
                <label>SRN Date</label>
                <SingleDatePicker
                  name='srnDate'
                  date={data.srnDate}
                  maxDate={getTodaysDate()}
                  minDate={data.salesInvoiceNumber?data.invoiceDate:undefined}
                  onChange={(name, date) =>
                    handleInputChange({
                      target: {
                        name: 'srnDate',
                      },
                      formattedValue: date,
                    })
                  }
                />
              </div>
            </Col>
            <Col md={3}>
              <div className='info payment-mode'>
                <label>Payment Mode</label>
                <CheckBox
                  className='ledger-info'
                  labelText='Cash'
                  checked={data.cashStatus}
                  onCheckBoxClick={() => {
                    handleInputChange({
                      formattedValue: !data.cashStatus,
                      target: {
                        name: 'cashStatus',
                        value: 'boolean',
                        checked: !data.cashStatus,
                      },
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

SrnSkuForm.propTypes = propTypes;

SrnSkuForm.defaultProps = defaultProps;

export default SrnSkuForm;
