import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { billingformConfig, formConfig } from './config';
import SpiltPayStyled from './SpiltPayStyled';
import { DialogFormWrapper } from '../../common';
import { collectionTypes } from '../../sales/collection/receipts/config';
import { getUser } from '../../../data/services';
import restClient from '../../../apiClient/rest';
import { getTodaysDate, hyphenPresentor } from '../../../utils/date';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { INVALID } from '../../../data/enums/ErrorMessage';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MessageStyled } from '../../../components/Input/TextStyled';
import { Icon ,Button} from '../../../v4/components';
import  { CustDet } from '../../../../src/views/sales/orderProcessing/received/salesInvoice/customerDetail/CustomerDetail';
import {
  Form, Input, CustomSelect, SingleDatePicker,
} from '../../../components/index';
import withAlert from '../../../utils/composition/withAlert';

const propTypes = {
  type: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  getLedgers: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  resetDialog: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  fetchAllData: PropTypes.func.isRequired,
  updatePayment: PropTypes.func.isRequired,
  changePaymentStatus: PropTypes.func.isRequired,
};

const defaultProps = {
  type: '',
  data: {},

};
class SplitPayment extends Component {
  constructor(props) {
    super(props);
    const user = getUser().Distributor;
    this.headers = {
      Authorization: 'Bearer asdfghjklasdfghjkl-12',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    this.billing = user.length
      ? {
        status: user[0].servicesUsed.billing.status,
        url: user[0].servicesUsed.billing.url,
        versionCode: user[0].servicesUsed.billing.versionCode,
      }
      : {};
    this.state = {
      outstandingAmount:0,
      disableOk: false,
      ledgers: [],
      cashBanklist: [],
      tagList: [],
      details: {
        tagId: 0,
        remarks: props.data.remarks,
        amount: props.data.amount,
        date: props.data.date,
      },
      ledgerDetail: [{
        toLedgerId: null,
        fromLedgerId: '',
        amount: '',
        toCustomerId: '',
      }],
      errorDisplay: false,
      distributorId: user.length
        ? user[0].id
        : null,
    };
  }

  componentDidMount() {
    const { type, data } = this.props;
    if (this.billing.status && type !== EVENT_OPERATION.DISCARDED && type !== EVENT_OPERATION.UPDATE) {
      // this.getTagList();
      this.getBillingLedgersList();
      this.getLedgersList();
      if (type === EVENT_OPERATION.READ) {
        const { paymentDetails } = data;
        const detail = {
          tagId: data.tagId,
          remarks: data.billingRemarks,
          date: data.date,
          amount: data.amount,
        };
        this.setState({ ledgerDetail: paymentDetails, details: detail });
      }
    }
  }

  onInputChange = (value, param) => {
    const { details } = this.state;
    details[param] = value;
    this.setState({ details, disableOk:false  });
  };

handleLedgerIconClick = (e, action, index = 0) => {
  e.preventDefault();
  const LedgerBase = {
    toLedgerId: null,
    fromLedgerId: '',
    amount: '',
    toCustomerId: '',
  };
  const { ledgerDetail } = this.state;
  if (action === 'remove') {
    ledgerDetail.splice(index, 1);
    this.setState(ledgerDetail);
  }
  if (action === 'add') {
    this.setState({ ledgerDetail: [...ledgerDetail, LedgerBase] });
  }
};
//need to change here
 handleLedgerInput = (value, index = 0, field = '',billingLedgerId=0) => {

  try{
  if(field === 'fromLedgerId'){
    const CustomerDetailinstance = new CustDet();

  CustomerDetailinstance.getCustomerOutstandingFromBilling(billingLedgerId).then(res => {
  
    if (res?.data?.outstandingAmount) {
      
      this.setState({outstandingAmount:res?.data?.outstandingAmount}); 
    } else {
      this.setState({outstandingAmount:0}); 
 
  }
})
  }
}
catch {
  this.setState({outstandingAmount:0}); 
}

 const { ledgerDetail } = this.state;
  const newArr = ledgerDetail.map((item, i) => {
    if (index === i) {
      if (field === 'amount') {
        return { ...item, [field]: value.formattedValue };
      }
      return { ...item, [field]: value };
    }
    return item;
  });
  this.setState({ ledgerDetail: newArr, disableOk:false });
};

handleRestError = (response) => {
  if (!response.ok) {
    throw Error(response.status);
  }

  return response;
};

 getBillingLedgersList = () => {
const {getBillingLedgers} = this.props;
const { distributorId } = this.state;

getBillingLedgers({
  distributorId,
  filter: {
    filters: [
        {
            column: "cash_bank_status",
            value: ["cash"]
        }
    ]
},
}, {
  handleSuccess: (res) => {
    this.setState({ cashBanklist: res?.data?.billingLedgers?.rows });
  },
});
 }

 getTagList = async () => restClient(`${this.billing.url}/api/v1/tags/getlist`, {
   headers: this.headers,
   method: 'GET',
 })
   .then(this.handleRestError)
   .then(response => response.json())
   .then((res) => {
     this.setState({ tagList: res.data.list });
   });

 getLedgersList = () => {
   const { distributorId } = this.state;
   const { getLedgers, data } = this.props;
   getLedgers({
     offset: 0,
     limit: 1000,
     filter: {
       filters: [{
         column: 'retail_outlet_id',
         value: [data.outlet.id.toString()],
       }, {
         column: 'active',
         value: ['true'],
       },
       ],
     },
     retailOutletId: data.outlet.id,
     distributorId,
   }, {
     handleSuccess: (response) => {
       this.setState(
         { ledgers: response.data.customers.rows || [] },
       );
     },
   });
 };


 onAPIRequestFailure = (error) => {
   const { displayAlert } = this.props;
   displayAlert(ALERT_TYPE.DANGER, error);
 };

onDialogSubmit = (type, dialogData, error) => {
  const {
    changePaymentStatus, getStatus, fetchAllData, data, resetDialog, updatePayment,
  } = this.props;
  const { ledgerDetail, details } = this.state;
  const input = {
    ids: [data.id],
    status: type,
  };
  const billingInput = {
    ...input,
    ledgerInfo: this.billing.status ? ledgerDetail : [],
    tagId: details.tagId,
    remarks: details.remarks,
    date: hyphenPresentor(details.date),
  };
  if (type === EVENT_OPERATION.RECONCILED) {
    if (error === 'error') {
      this.setState({ errorDisplay: true });
    } else {
      this.setState({ errorDisplay: false, disableOk:true  });

      changePaymentStatus(this.billing.status ? billingInput : input, {
        handleSuccess: () => {
          getStatus('Successfully Updated', '');
          fetchAllData();
          resetDialog();
        },
        handleError: err => (this.onAPIRequestFailure(err)),
      });
    }
  }
  if (type === EVENT_OPERATION.DISHONORED) {
    this.setState({disableOk:true});
    changePaymentStatus(input, {
      handleSuccess: () => {
        getStatus('Successfully Updated', '');
        fetchAllData();
        resetDialog();
      },
      handleError: err => this.onAPIRequestFailure(err),
    });
  }
  if (type === EVENT_OPERATION.DISCARDED) {
    this.setState({disableOk:true});
    changePaymentStatus(input, {
      handleSuccess: () => {
        getStatus('Successfully Removed', '');
        fetchAllData();
        resetDialog();
      },
      handleError: err => this.onAPIRequestFailure(err),
    });
  }
  if (type === EVENT_OPERATION.UPDATE) {
    this.setState({disableOk:true});
    updatePayment(
      {
        paymentId: data.id,
        amount: details.amount,
        remarks: details.remarks,
        date: hyphenPresentor(details.date),
      },
      {
        handleSuccess: () => {
          getStatus('Successfully Updated', '');
          fetchAllData();
          resetDialog();
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  }
};

render() {
  const {
    errorDisplay, ledgerDetail, cashBanklist, ledgers, tagList, details, disableOk,outstandingAmount
  } = this.state;
  const { data, type, resetDialog } = this.props;
  const validation = ledgerDetail.map(a => a.amount).reduce((acc, cur) => acc + cur, 0);

  const selectedLedgerIdList = ledgerDetail.map(element => element.fromLedgerId);
  const getDropDownForLedgerList = customerId => ledgers
    .filter(list => list.customerId === customerId || !selectedLedgerIdList.includes(list.customerId));

  return (
    <SpiltPayStyled>
      <div>
        {type && (
        <DialogFormWrapper
          formConfig={this.billing.status ? billingformConfig[type] : formConfig[type]}
          onDialogSubmit={this.onDialogSubmit}
          onDialogCancel={resetDialog}
          disableDialogClose
          disableOk={disableOk}
          type={type}
          formTitle={type !== EVENT_OPERATION.DISCARDED ? data.outlet.title : ''}
          withOutPadding={type !== EVENT_OPERATION.DISCARDED}
          extraValidation={this.billing.status && validation !== details.amount}
          renderDialog={({
            enableErrorDisplay,
            refsObj,
          }) => (
            <Fragment>
              {
                type !== EVENT_OPERATION.DISCARDED ? (
                  <Form className="form-wrap">
                    <Row className="type-section">
                      <Col md={4}>
                        <CustomSelect
                          options={collectionTypes}
                          labelContent="Payment Type"
                          className="custom-select"
                          placeholder="Select Payment Type"
                          getOptionValue={({ label }) => label}
                          getOptionLabel={({ title }) => title}
                          disabled
                          value={collectionTypes.filter(({ label }) => label === data.collectionType)}
                        />
                      </Col>
                      <Col md={4}>
                        <div className="single-date-picker">
                          <label>Received Date</label>
                          <SingleDatePicker
                            name="startDate"
                            date={details.date || getTodaysDate()}
                            onChange={(name, date) => this.onInputChange(date, 'date')}
                            disabled={type === EVENT_OPERATION.READ}
                            maxDate={getTodaysDate()}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <Input
                          name="amount"
                          type="number"
                          placeholder="Amount"
                          labelContent="Amount"
                          value={details.amount}
                          disabled={type !== EVENT_OPERATION.UPDATE}
                          onChange={event => this.onInputChange(event.formattedValue, 'amount')}
                        />
                      </Col>
                    </Row>
                    { this.billing.status && type !== EVENT_OPERATION.DISHONORED && type !== EVENT_OPERATION.UPDATE && (
                      <>
                        <Row className={type === EVENT_OPERATION.READ ? 'type-section mt-24 disable' : 'type-section mt-24'}>
                          {ledgerDetail.map((ledger, index) => (
                            <>
                             {
                              this.billing?.versionCode === 2 ?<Col md={4}>
                                  <CustomSelect
                                    labelContent="To Cash/Bank"
                                    className="custom-select"
                                    placeholder="To Ledger "
                                    options={cashBanklist}
                                    getOptionValue={({ externalCode }) => externalCode}
                                    getOptionLabel={({ title }) => title}
                                    onChange={e => this.handleLedgerInput(e.externalCode, index, 'toCustomerId' )}
                                    value={cashBanklist.filter(({ externalCode }) => externalCode === ledger.toCustomerId)}
                                    enableValidation
                                    enableErrorDisplay={enableErrorDisplay}
                                    ref={this.billing.status ? ref => (refsObj.toCustomerId = ref) : ''}
                                  />
                                </Col>:
                                <Col md={4}>
                                  <CustomSelect
                                    labelContent="To Cash/Bank"
                                    className="custom-select"
                                    placeholder="To Ledger "
                                    options={cashBanklist}
                                    getOptionValue={({ customerId }) => customerId}
                                    getOptionLabel={({ title }) => title}
                                    onChange={e => this.handleLedgerInput(e.customerId, index, 'toLedgerId')}
                                    value={cashBanklist.filter(({ customerId }) => customerId === ledger.toLedgerId)}
                                    enableValidation
                                    enableErrorDisplay={enableErrorDisplay}
                                    ref={this.billing.status ? ref => (refsObj.toLedgerId = ref) : ''}
                                  />
                                </Col>
                              }
                              <Col md={4} className="from-ledger">
                                <CustomSelect
                                  labelContent="From Ledger"
                                  className="custom-select"
                                  placeholder="From Ledger "
                                  options={getDropDownForLedgerList(ledgers.customerId)}
                                  getOptionValue={({ customerId }) => customerId}
                                  getOptionLabel={({ title }) => title}
                                  onChange={e => this.handleLedgerInput(e.customerId, index, 'fromLedgerId',e.billingLedgerId)}
                                  value={ledgers.filter(({ customerId }) => customerId === ledger.fromLedgerId)}
                                  enableValidation
                                  enableErrorDisplay={enableErrorDisplay}
                                  ref={this.billing.status ? ref => (refsObj.fromLedgerId = ref) : ''}
                                />
                                {ledger.fromLedgerId && (
                                <div className="outstanding-due">
                                  Outstanding Due:
                                  <span className="due-amt">
                                    {outstandingAmount >= 0
                            ? `${fixedFloatAndCommas(outstandingAmount)}   Cr `
                            : `${fixedFloatAndCommas(outstandingAmount * -1)}  Dr `}
                                  </span>
                                </div>
                                )}
                              </Col>
                              <Col md={3} className="ledger-amount-wrap">
                                <Input
                                  name="amount"
                                  type="number"
                                  placeholder="Amount"
                                  labelContent="Amount"
                                  value={ledger.amount}
                                  onChange={e => this.handleLedgerInput(e, index, 'amount')}
                                />
                                { ledgerDetail[index].amount > details.amount || errorDisplay ? (
                                  <MessageStyled className="form-error">
                                    <span className="error-message">
                                      { <Icon iconName="exclamation-full" />}
                                      {errorDisplay ? `The sum of amount should be ${details.amount} ` : INVALID}
                                    </span>
                                  </MessageStyled>
                                ) : null}
                              </Col>
                              <Col md={1} className="cross-icon">
                                <Icon
                                  iconName="times"
                                  primary
                                  onClick={e => this.handleLedgerIconClick(e, 'remove', index)}
                                />
                              </Col>
                            </>
                          ))}
                          {type === EVENT_OPERATION.RECONCILED && <Button medium secondary iconName="plus" onClick={e => this.handleLedgerIconClick(e, 'add')} />}
                        </Row>
                        <Row className={type === EVENT_OPERATION.READ ? 'mt-24 disable' : 'mt-24'}>

                          <Col md={4}>
                            <CustomSelect
                              options={tagList}
                              labelContent="Tag"
                              className="custom-select"
                              placeholder="Select Tag"
                              getOptionValue={({ idTags }) => idTags}
                              getOptionLabel={({ title }) => title}
                              value={tagList.filter(({ idTags }) => idTags === details.tagId)}
                              onChange={event => this.onInputChange(event.idTags, 'tagId')}
                            />
                          </Col>

                          <Col md={8}>
                            <Input
                              name="remarks"
                              type="text"
                              placeholder="Remarks"
                              labelContent="Remarks"
                              value={details.remarks}
                              onChange={event => this.onInputChange(event.formattedValue, 'remarks')}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    {type === EVENT_OPERATION.UPDATE && (
                    <Col md={8} className="mt-24">
                      <Input
                        name="remarks"
                        type="text"
                        placeholder="Remarks"
                        labelContent="Remarks"
                        value={details.remarks}
                        onChange={event => this.onInputChange(event.formattedValue, 'remarks')}
                      />
                    </Col>
                    )}
                  </Form>
                ) : 'Are you sure you want to remove this record?'
           }
            </Fragment>
          )}
        />
        )}
      </div>
    </SpiltPayStyled>
  );
}
}

SplitPayment.propTypes = propTypes;

SplitPayment.defaultProps = defaultProps;

export default withAlert()(SplitPayment);
