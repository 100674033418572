import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from '../../../../../components';
import { Switch } from '../../../../../v4/components';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import Popover from '../../../../../components/Popover';

const propTypes = {
  skuList: PropTypes.arrayOf(Object).isRequired,
  checkedSkus: PropTypes.arrayOf(Object).isRequired,
  skus: PropTypes.arrayOf(Object).isRequired,
  getStatus: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const FocusedSkuDistribution = ({ ...props }) => {
  const { skuList, checkedSkus, getStatus, skus, handleSubmit, getSKUList, displayAlert, permission } = props;

  const [skuLists, setSkuLists] = useState(skuList);
  const [popUpSKUs, setPopUpSKUs] = useState([])

  const handleInput = (text) => {
    const formattedText = text.replace(/\s+/g, '').toLowerCase()
    const search = (d) => {
      return d.title.replace(/\s+/g, '').toLowerCase().search(formattedText) !== -1;
    }
    const filterData = skuList.filter((d) => search(d));
    setSkuLists(filterData);
  };

  const getSKUTitleList = (catalogDetailId) =>{
    getSKUList({ catalogId:7,catalogDetailId },{
      handleSuccess: (response) => {
        const resData = response.data?.catalogLevelDetails?.rows||[];
        const skusList = resData?.map((a)=>a.SKUs).flat();
        setPopUpSKUs(skusList);
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    })
  }

  const getNumberOfSkus = () => {
    return (
      <div className='pop-up-sku'>
        {popUpSKUs.map((sku)=><span >{sku.title} </span>)}
      </div>
    )
  }

  return (
    <div className='sbd-panel flex-1 sbd-table'>
      <div className='sbd-panel-list'>
        <div className='sbd-detail-table'>
          <span className='info-text'>↙↙ Please choose tabs and enable Focused SKU for each SKU.</span>
          <div className='dropdown-head'>
            <h3>Focused SKU Distribution</h3>
            <Search handleSearchInput={handleInput} />
          </div>
          <div className='table-wrap'>
            <table>
              <thead>
                <tr>
                  <th>SKU Family</th>
                  <th className='text-right'>No. of SKU</th>
                  <th className='text-right'>Status</th>
                </tr>
              </thead>
              <tbody>
                {skuLists.map((d) => (
                  <tr key={d.id} className={checkedSkus.includes(d.id) ? 'list-active' : ''}>
                    <td>{d.title}</td>
                    <td className='text-right popover_td' onClick={()=>getSKUTitleList(d.id)}>
                      {d.skuCount!==0?<div className='simple-popup'>
                        <Popover
                          popoverIcon={d.skuCount}
                          headerTitle={d.title}
                        >
                          <span className='arrow-up' />
                          {getNumberOfSkus()}
                        </Popover>
                      </div>:(d.skuCount)}
                    </td>
                    <td className='text-right'>
                      {
                        <Switch
                          checked={getStatus(d.id) || skus.includes(d.id)}
                          onToggle={(e) => handleSubmit(d.id, e)}
                          disabled={!permission?.update}
                        />
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

FocusedSkuDistribution.propTypes = propTypes;

export default withAlert()(FocusedSkuDistribution);
