import React, {
  Fragment, useEffect, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { Search, } from '../../../../../components';
import {
  Switch, Button, CheckBox
} from '../../../../../v4/components';
import Popover from '../../../../../components/Popover';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';

const propTypes = {
  skuList: PropTypes.arrayOf(Array).isRequired,
  checkedSkuFamilies: PropTypes.arrayOf(Array).isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  setCheckedSkuFamilies: PropTypes.func.isRequired,
  getSBDStatus: PropTypes.func.isRequired,
  skus: PropTypes.arrayOf({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  handleSBDSubmit: PropTypes.func.isRequired,
  skuGroupList: PropTypes.arrayOf({
    id: PropTypes.number,
    count: PropTypes.number,
    skus: PropTypes.array,
  }).isRequired,
  totalSkuFamilies: PropTypes.arrayOf({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};


const SBDDistribution = ({ ...props }) => {
  const {
    skuList,
    checkedSkuFamilies,
    onCreateGroup,
    setCheckedSkuFamilies,
    getSBDStatus,
    skus,
    handleSBDSubmit,
    skuGroupList,
    totalSkuFamilies,
    displayAlert,
    getSKUList,
    getSBDSku,
    permission,
  } = props;
  const [skuLists, setSkuLists] = useState(skuList);
  const [skuGroupLists, setSkuGroupLists] = useState(skuGroupList);
  const [popUpSKUs, setPopUpSKUs] = useState([])
  const skuListRef = useRef([]);
  const skuGroupListRef = useRef([]);

  const searchWithData = (data, text) => {
    if (text) {
      const filteredList = data.filter((s) => s.title.toLowerCase().includes(text.toLowerCase())) || [];
      return filteredList;
    }
    return (
      [...skuListRef.current,
        ...skuGroupListRef.current]
    )

  };
  const handleInput = (text) => {
    const groupSearchFilter = (sku) => searchWithData(totalSkuFamilies, text).filter((d) => sku === d.id).length !== 0;
    const groupFilter = (group) => group.skus.filter((sku) => groupSearchFilter(sku));
    const groupFilterData = skuGroupList.filter((group) => groupFilter(group).length !== 0);
    const skuGroup = skuGroupList.map((group) => group.skus).flat()
    const currentGroupList = totalSkuFamilies.filter((d) =>  skuGroup.includes(d.id))
    skuGroupListRef.current=currentGroupList

    setSkuLists(searchWithData(skuList, text));
    setSkuGroupLists(groupFilterData);
  };

  const skuGroupFunction = (skus, i) => {
    if (i === 0) {
      return 'sku-group';
    }
    if (skus.length === 3 && i === 1) {
      return 'sku-group';
    }
    if (skus.length === 4 && (i === 1 || i === 2)) {
      return 'sku-group';
    }
    if (skus.length === 5 && (i === 1 || i === 2 || i === 3)) {
      return 'sku-group';
    }
    return null;
  };

  const getNumberOfSkus = () => {
    return (
      <div className='pop-up-sku'>
        {popUpSKUs.map((sku)=><span >{sku} </span>)}
      </div>
    )
  }

  const getSKUTitleList = (sbdId) =>{
    getSBDSku({ sbdId },{
      handleSuccess: (response) => {
        const resData = response.data?.getSBDSku||[];
        const skus = resData?.map((a)=>a.title);
        setPopUpSKUs(skus);
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    })
  }

  useEffect(() => {
    if (skuList.length !== skuLists.length) {
      setSkuLists(skuList);
    }
    if (skuGroupList.length !== skuGroupLists.length) {
      setSkuGroupLists(skuGroupList);
    }
  }, [skuList, skuGroupList]);

  useEffect(() => {
    skuListRef.current = skuList;
  }, [skuLists])

  return (
    <div className='sbd-panel flex-1 sbd-table'>
      <div className='sbd-panel-list'>
        <div className='sbd-detail-table'>
          <span className='info-text'>
            ↙↙ Please choose tabs and enable SBD for each SKU. Check multiple SKU to create group SBD.
          </span>
          <div className='dropdown-head'>
            <h3>SBD Distribution</h3>
            <Search handleSearchInput={handleInput} />
          </div>
          {checkedSkuFamilies.length !== 0 && (
            <div className='item-selected'>
              <span className='text'>{`${checkedSkuFamilies.length} of ${skuList.length} items selected`}</span>
              <Button primary onClick={() => onCreateGroup()}
                disabled={!permission?.update}>
                Create Group
              </Button>
            </div>
          )}
          <div className='table-wrap'>
            <table>
              <thead>
                <tr>
                  <th />
                  <th>SKU Family</th>
                  <th className='text-right'>No. of SKU</th>
                  <th className='text-right'>Status</th>
                </tr>
              </thead>
              <tbody>


                {skuGroupLists.length !== 0 &&
                  skuGroupLists?.map((d) => (
                    <Fragment key={d.id}>
                      {d.skus?.map((id, i) => (
                        <tr key={id} className={skuGroupFunction(d.skus, i)}>
                          <td />
                          <td>
                            {totalSkuFamilies.find((d) => d.id === id)?.title}
                          </td>
                          <td className='text-right popover_td ' onClick={()=>getSKUTitleList(d.id)}>
                            <div className='simple-popup'>
                              <Popover
                                popoverIcon={d.skuCount}
                                headerTitle={ totalSkuFamilies.find((d) => d.id === id)?.title}
                              >
                                <span className='arrow-up' />
                                {getNumberOfSkus()}
                              </Popover>
                            </div>
                            {/* } */}
                          </td>
                          <td className='text-right'>
                            {i === 0 && (
                              <Switch
                                checked={getSBDStatus(id) || skus.includes(id)}
                                onToggle={(e) => handleSBDSubmit(d.skus[i], e)}
                                disabled={!permission?.update}

                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                {skuLists.map((d) => (
                  <tr key={d.id} className={checkedSkuFamilies.includes(d.id) ? 'list-active' : ''}>
                    <td>
                      <CheckBox
                        checked={checkedSkuFamilies.includes(d.id)}
                        onCheckBoxClick={() => {
                          setCheckedSkuFamilies(d.id);
                        }}
                        disabled={getSBDStatus(d.id) || skus.includes(d.id)}
                      />
                    </td>
                    <td>{d.title}</td>
                    <td className='text-right popover_td'  onClick={()=>getSKUTitleList(d.sbdDetailsId)}>
                      {d.count?<div className='simple-popup'>
                        <Popover
                          popoverIcon={d.count}
                          headerTitle={d.title}
                        >
                          <span className='arrow-up' />
                          {getNumberOfSkus()}
                        </Popover>
                      </div>:(0)}
                    </td>
                    <td className='text-right'>
                      {
                        <Switch
                          checked={getSBDStatus(d.id) || skus.includes(d.id)}
                          onToggle={(e) => handleSBDSubmit([d.id], e)}
                          disabled={!permission?.update}
                        />
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAlert()(SBDDistribution);
