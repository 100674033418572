import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  Form, Input, SingleDatePicker
} from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import CustomAutoComplete from '../../../../../components/CustomAutoComplete';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { getTodaysDate } from '../../../../../utils/date';
import { Icon } from '../../../../../v4/components';

const propTypes = {
  handleInputChange: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  outletList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
  invoiceNumberStatus: PropTypes.bool,
  subDUser: PropTypes.bool,
  vendorList: PropTypes.instanceOf(Array),
  loadingGrnStatus: PropTypes.bool,
  invoiceNumberList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  permission: PERMISSION_OBJ,
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  outletList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  invoiceNumberStatus: false,
  vendorList: [],
  handleInputChange: () => null,
  subDUser: false,
  loadingGrnStatus: false,
  invoiceNumberList: [],
};

const PrnSkuForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    subDUser,
    vendorList,
    permission,
    distributorList,
    loadingGrnStatus,
    handleInputChange,
    invoiceNumberList,
    enableErrorDisplay,
    handleDropDownChange,
    invoiceNumberStatus,
  } = props;

  const onInvoiceNumberSelection = (formattedValue, name) => {
    handleInputChange({ formattedValue, target: { name } });
  };

  return (
    <Form className='grn-form'>
      <Row>
        <Col md={9}>
          <Row>
            {!subDUser && (
              <Fragment>
                <Col className='mb-24' md={8}>
                  <CustomSelect
                    enableValidation
                    labelContent='Distributor'
                    options={distributorList}
                    className='custom-select'
                    placeholder='Select Distributors'
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    enableErrorDisplay={enableErrorDisplay}
                    ref={(ref) => (refsObj.distributorId = ref)}
                    onChange={(event) => handleDropDownChange(event.id, ['distributorId'], 'distributor', event)}
                    value={distributorList.filter(({ id }) => id === data.distributorId)}
                  />
                </Col>
                <Col md={6} />
              </Fragment>
            )}
          </Row>
          <Row>
            <Col className='mb-24' md={4}>
              <div className='grn-label-text'>
                {/*  <label>GRN Number</label>
                <input
                  name="grnInvoiceNumber"
                  type="text"
                  value={data.grnInvoiceNumber}
                  list="invoiceNumberListId"
                  autoComplete="off"
                  onChange={e => inputChange(e, 'input')}
                />
                <datalist id={
                  invoiceNumberList.length > 0
                   ? 'invoiceNumberListId' : 'invoiceNumberListIdTemporary'}>
                  { invoiceNumberList.map((item,key) => <option key={key} value={item} />)}
                </datalist> */}
                <CustomAutoComplete
                  name='grnInvoiceNumber'
                  onChange={handleInputChange}
                  dropDownList={invoiceNumberList}
                  labelContent='GRN Invoice Number'
                  placeholder='GRN Invoice Number'
                  dropDownValueKey={null}
                  dropDownDisplayKey={null}
                  disabled={loadingGrnStatus}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  // ref={(ref) => (refsObj.grnInvoiceNumber = ref)}
                  onDropDownSelection={onInvoiceNumberSelection}
                />
                {invoiceNumberStatus && (
                  <div className='success-label'>
                    <Icon iconName='check-circle-full' />
                    <span>Valid Number</span>
                  </div>
                )}
              </div>
            </Col>
            {!permission.BILLING && (
              <Col className='mb-24' md={4}>
                <Input
                  name='prnInvoiceNumber'
                  type='text'
                  value={data.prnInvoiceNumber}
                  labelContent='PRN Invoice Number'
                  placeholder='PRN Invoice Number'
                  onChange={(event) => handleInputChange(event)}
                />
              </Col>
            )}
            <Col className='mb-24' md={4}>
              <CustomSelect
                enableValidation
                labelContent='Vendor'
                options={vendorList}
                className='custom-select'
                placeholder='Select Vendors'
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                enableErrorDisplay={enableErrorDisplay}
                ref={(ref) => (refsObj.vendorId = ref)}
                onChange={(event) => handleDropDownChange(event.id, ['vendorId'])}
                value={vendorList.filter(({ id }) => id === data.vendorId)}
              />
            </Col>
            <Col className='m-0' md={4}>
              <div className='single-date-picker'>
                <label>PRN Date</label>
                <SingleDatePicker
                  name='prnDate'
                  date={data.prnDate}
                  maxDate={getTodaysDate()}
                  minDate={data.grnInvoiceNumber ? data.grnDate : undefined}
                  onChange={(name, date) =>
                    handleInputChange({
                      target: {
                        name: 'prnDate',
                      },
                      formattedValue: date,
                    })
                  }
                />
              </div>
            </Col>
            <Col className='mb-24' md={8}>
              <Input
                type='text'
                name='reason'
                enableValidation
                value={data.reason}
                placeholder='Add Remarks'
                labelContent='Remarks'
                ref={(ref) => (refsObj.reason = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleInputChange(event)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

PrnSkuForm.propTypes = propTypes;

PrnSkuForm.defaultProps = defaultProps;

const PrnSkuFormWithErrorAndLoading = withLoading(PrnSkuForm);

export default PrnSkuFormWithErrorAndLoading;
