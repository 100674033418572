import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import { Input } from '../../../../../components';
import PermissionStyled from './PermissionStyled';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import CustomSelect from '../../../../../components/CustomSelect';
import SyncDialog from './SyncDialog';
import {
  Switch, Icon, Button, CheckBox
} from '../../../../../v4/components';
import { versionCodeList } from '../config';

const propTypes = {
  servicesUsed: PropTypes.objectOf(Object).isRequired,
  onInputChange: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool.isRequired,
  refsObj: PropTypes.objectOf(Object).isRequired,
  srn: PropTypes.objectOf(Object).isRequired,
  readyStock: PropTypes.bool.isRequired,
  srnChanged: PropTypes.func.isRequired,
  srnService: PropTypes.bool.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  handleSkuSync: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  handleDialogCancelClick: PropTypes.func.isRequired,
  handleDialogOkClick: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(Object).isRequired,
  skuSync: PropTypes.objectOf(Object).isRequired,
  appPermissions: PropTypes.objectOf(Object).isRequired,
  marketPlacePermissions: PropTypes.objectOf(Object),
  marketPlace: PropTypes.bool,
  marketChanged: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onUploadClick: PropTypes.func.isRequired,
  vendorId: PropTypes.number,
  vendorMapSection: PropTypes.bool.isRequired,
  crudMode: PropTypes.string.isRequired,
  batchImplementation: PropTypes.bool.isRequired,
  enableManualGRN: PropTypes.bool.isRequired,
  outletAutoApproval: PropTypes.bool.isRequired,
};

const defaultProps = {
  vendorId: 0,
  marketPlace: false,
  marketPlacePermissions: {},
};

const Permission = (props) => {
  const {
    servicesUsed,
    onInputChange,
    enableErrorDisplay,
    refsObj,
    srn,
    readyStock,
    srnChanged,
    srnService,
    handleButtonSubmit,
    vendors,
    vendorId,
    vendorIdv2,
    onDropDownChange,
    vendorMapSection,
    appPermissions,
    marketPlacePermissions,
    marketPlace,
    marketChanged,
    crudMode,
    handleSkuSync,
    handleDialogOkClick,
    onDownloadClick,
    onUploadClick,
    handleDialogCancelClick,
    skuSync,
    outletAutoApproval,
    batchImplementation,
    enableManualGRN,
  } = props;

  return (
    <div className='create-ui'>
      <div className={`create-ui-inner permission ${crudMode === EVENT_OPERATION.READ ? 'disabled' : ''}`}>
        <PermissionStyled>
          <Col md={5}>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'servicesUsed',
                    ['servicesUsed', 'billing', 'status'],
                  );
                }}
                checked={servicesUsed.billing.status}
                name='active'
                labelContent='Billing'
              />
              {servicesUsed.billing.status && (
                <>
                  <div className='input-url-wrap'>
                    <Input
                      name='url'
                      type='text'
                      enableValidation
                      labelContent='Please enter the url for billing'
                      value={servicesUsed.billing.url}
                      enableErrorDisplay={enableErrorDisplay}
                      ref={(ref) => (refsObj.billingUrl = ref)}
                      onChange={(event) => onInputChange(event, 'servicesUsed', ['servicesUsed', 'billing', 'url'])}
                    />
                    <Button
                      outlined
                      onClick={() => {
                        handleButtonSubmit();
                      }}
                    >
                      <Icon iconName='check' />
                    </Button>
                  </div>
                  <div
                    className={
                      servicesUsed.billing.url !== '' || vendorMapSection
                        ? 'billing-input active'
                        : 'billing-input disabled'
                    }
                  >
                    <CustomSelect
                      labelContent='Version Code'
                      placeholder='Select'
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      options={versionCodeList}
                      name='versionCode'
                      value={versionCodeList.filter((d) => d.id === servicesUsed?.billing?.versionCode)}
                      onChange={(event) => onDropDownChange(event.id, ['servicesUsed', 'billing', 'versionCode'])}
                    />
                  </div>
                  <div
                    className={
                      servicesUsed.billing.url !== '' || vendorMapSection
                        ? 'billing-input active'
                        : 'billing-input disabled'
                    }
                  >
                    {servicesUsed.billing.versionCode===1?<CustomSelect
                      labelContent='Vendor Mapping'
                      placeholder='Select'
                      getOptionValue={({ customerId }) => customerId}
                      getOptionLabel={({ title }) => title}
                      options={vendors}
                      name='vendorLedgerId'
                      value={vendors.filter((d) => d.customerId === vendorId)}
                      onChange={(event) => onDropDownChange(event.customerId, ['vendorLedgerId'])}
                    />:
                      <CustomSelect
                        labelContent='Vendor Mapping'
                        placeholder='Select'
                        getOptionValue={({ externalCode }) => externalCode}
                        getOptionLabel={({ title }) => title}
                        options={vendors}
                        name='vendorLedgerIdv2'
                        value={vendors.filter((d) => d.externalCode === vendorIdv2)}
                        onChange={(event) => onDropDownChange(event.externalCode, ['vendorLedgerIdv2'])}
                      />}
                  </div>
                  {servicesUsed.billing.url && (
                    <div className='sync-btn-wrap'>
                      <Button className='sync-btn' onClick={handleSkuSync} disabled={skuSync.count}>
                        <Icon iconName='reload' />
                        <span>Sync Sku & Stock Ledger</span>
                      </Button>
                      <Button
                        secondary
                        iconName='download'
                        iconBtnSmall
                        className='sync-btn'
                        onClick={() => onDownloadClick()}
                      />
                    </div>
                  )}
                  {!servicesUsed.billing.url && !vendorMapSection && (
                    <p className='input-error-info'>
                      <Icon iconName='info-circle-open' />
                      <span className='text'>Enable only when billing url is set</span>
                    </p>
                  )}
                  <div>
                    <span>Upload Ledger Mapping</span>
                  </div>
                  <div>
                    <Button
                      secondary
                      iconName='upload'
                      iconBtnSmall
                      className='sync-btn'
                      onClick={() => onUploadClick(EVENT_OPERATION.UPLOAD)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'salesReturnPolicy',
                    ['salesReturnPolicy', 'status'],
                  );
                }}
                checked={srnService}
                name='active'
                labelContent='Sales Return Policy'
              />
              {srnService && (
                <div className='permission-choose'>
                  <label>Choose one</label>
                  <div>
                    <label className='radio-list'>
                      <input
                        type='radio'
                        name='srn'
                        value='Full'
                        onChange={(e) => srnChanged(e)}
                        checked={srn ? srn.full : false}
                      />
                      <span className='text'>Full</span>
                    </label>
                    <label className='radio-list'>
                      <input
                        type='radio'
                        name='srn'
                        value='Partial'
                        onChange={(e) => srnChanged(e)}
                        checked={srn ? srn.partial : false}
                      />
                      <span className='text'>Partial</span>
                    </label>
                    <label className='radio-list'>
                      <input
                        type='radio'
                        name='srn'
                        value='Both'
                        onChange={(e) => srnChanged(e)}
                        checked={srn ? srn.both : false}
                      />
                      <span className='text'>Both</span>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'readyStock',
                    ['readyStock'],
                  );
                }}
                checked={readyStock}
                name='active'
                labelContent='Ready Stock'
              />
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'servicesUsed',
                    ['servicesUsed', 'logistic', 'status'],
                  );
                }}
                checked={servicesUsed.logistic.status}
                name='active'
                labelContent='Logistics'
              />
            </div>

            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'servicesUsed',
                    ['servicesUsed', 'delivery', 'status'],
                  );
                }}
                checked={servicesUsed.delivery.status}
                name='active'
                labelContent='Delivery'
              />
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'marketPlacePermissions',
                    ['marketPlacePermissions', 'status'],
                  );
                }}
                checked={marketPlace}
                name='active'
                labelContent='Marketplace'
              />
              {marketPlace && (
                <div className='permission-choose'>
                  <div>
                    <label className='radio-list'>
                      <CheckBox
                        name='daraz'
                        onCheckBoxClick={(e) => marketChanged(e)}
                        checked={marketPlacePermissions ? marketPlacePermissions.daraz : false}
                      />
                      <span className='text'>Daraz</span>
                    </label>
                    <label className='radio-list'>
                      <CheckBox
                        name='meroKirana'
                        onCheckBoxClick={(e) => marketChanged(e)}
                        checked={marketPlacePermissions ? marketPlacePermissions.meroKirana : false}
                      />
                      <span className='text'>MeroKirana</span>
                    </label>
                    <label className='radio-list'>
                      <CheckBox
                        name='sastoDeal'
                        onCheckBoxClick={(e) => marketChanged(e)}
                        checked={marketPlacePermissions ? marketPlacePermissions.sastoDeal : false}
                      />
                      <span className='text'>Sastodeal</span>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'appPermissions',
                    ['appPermissions', 'sales'],
                  );
                }}
                checked={appPermissions && appPermissions.sales}
                name='active'
                labelContent='Sales App'
              />
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'appPermissions',
                    ['appPermissions', 'retailer'],
                  );
                }}
                checked={appPermissions && appPermissions.retailer}
                name='active'
                labelContent='Retailer App'
              />
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'appPermissions',
                    ['appPermissions', 'merchandiser'],
                  );
                }}
                checked={appPermissions && appPermissions.merchandiser}
                name='active'
                labelContent='Merchandiser App'
              />
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'appPermissions',
                    ['appPermissions', 'supervisor'],
                  );
                }}
                checked={appPermissions && appPermissions.supervisor}
                name='active'
                labelContent='Supervisor App'
              />
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'outletAutoApproval',
                    ['outletAutoApproval'],
                  );
                }}
                checked={outletAutoApproval}
                name='active'
                labelContent='Outlet Auto Approval'
              />
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'batchImplementation',
                    ['batchImplementation'],
                  );
                }}
                checked={batchImplementation}
                name='active'
                labelContent='SKU Batch Implementation'
              />
            </div>
            <div className='status-switch'>
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'enableManualGRN',
                    ['enableManualGRN'],
                  );
                }}
                checked={enableManualGRN}
                name='active'
                labelContent='Manual GRN'
              />
            </div>
          </Col>
        </PermissionStyled>
      </div>
      {skuSync.show && (
        <SyncDialog data={skuSync} onDialogSubmit={handleDialogOkClick} onDialogCancel={handleDialogCancelClick} />
      )}
    </div>
  );
};

Permission.propTypes = propTypes;

Permission.defaultProps = defaultProps;

export default Permission;
