import { EVENT_OPERATION } from '../../data/enums/EventOperation';
import { JWT } from '../../environment';
import { voucherEncoder } from '../../utils/transformation';
import { getUser, getUserBillingCredentials } from '../../data/services/user';
import { newWindow } from '../../utils/newWindow';
import { isObjectEmpty } from '../../utils/objectPrototypes';
import { VIEW_LIST } from '../reports/productive-coverage/config';
import { COVERAGE_TYPE } from '../../data/enums/GraphQL';
import { DOMAIN } from '../../data/enums/config';
import { SORTING } from '../../data/enums';
import { encryptText } from '../../utils/encryption';
import LEDGERS from '../../data/enums/Ledgers';
import { USER_ROLE_TITLE } from '../../data/enums/UserRole';

const isConfirmationType = (type) =>
  type === EVENT_OPERATION.DELETE ||
  type === EVENT_OPERATION.BULK_DELETE ||
  type === EVENT_OPERATION.UPDATE_STATUS ||
  type === EVENT_OPERATION.SAVE ||  type === EVENT_OPERATION.CANCEL;

const getCheckboxStatus = (selectedList = [], id = '') => selectedList.includes(id);

const getFlattenedOrders = (orders) => (orders.map((order) => order.Lines.map((item) => item)) || []).flat() || [];

const isError = (response = {}) => response.errors && response.errors.length > 0;

const getAllIds = (list) => list.map((item) => item.id);

const errorMessageParser = (err = {}) => {
  // can be array
  // "graphQLErrors", "networkError", "message", "extraInfo"

  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    return err.graphQLErrors[0].message;
  }

  if (err.networkError) {
    return err.networkError.message;
  }

  if (err.message) {
    return err.message;
  }

  return 'Error in operation. Please try again later';
};

const imageSliderListFormatter = (list, urlPrefix = '', style = { w: 500, h: 500 }) =>
  list.map((imageObj) => ({
    thumbnail: imageObj.thumbnail ? `${urlPrefix}${imageObj.thumbnail}` : '',
    w: imageObj.w ? imageObj.w : style.w,
    h: imageObj.h ? imageObj.h : style.h,
    title: imageObj.title || '',
    src: imageObj.src ? `${urlPrefix}${imageObj.src}` : '',
  }));

const imageSliderListFormatterWithSrc = (list, urlPrefix = '', style = { w: 500, h: 500 }) =>
  list.map((imgSrc, index) => ({
    thumbnail: `${urlPrefix}${imgSrc}`,
    w: style.w,
    h: style.h,
    title: `${index}`,
    src: `${urlPrefix}${imgSrc}`,
  }));

const updateSkuUsageDates = (state, stateUpdater, values) => {
  state.manufactureDate = values.manufacture;
  state.expiryDate = values.expiry;
  stateUpdater(state);
};

const handleGRNSKUBatchSelect = (value, skuBatchList, state, stateUpdater, rateUpdater = () => null) => {
  const selectedBatch = skuBatchList.filter(({ id }) => id === value)[0];
  const usageDate = {
    manufacture: selectedBatch ? selectedBatch.manufactureDate : new Date().toISOString().slice(0, 10),
    expiry: selectedBatch ? selectedBatch.expiryDate : new Date().toISOString().slice(0, 10),
  };
  updateSkuUsageDates(state, stateUpdater, usageDate);
  rateUpdater(state, stateUpdater, selectedBatch);
};

const handleSRNSkuBatchSelect = (value, skuBatchList, state, stateUpdater, rateUpdater = () => null) => {
  const selectedBatch = skuBatchList.filter(({ id }) => id === value)[0];
  rateUpdater(state, stateUpdater, selectedBatch);
};

const handleSkuBatchSelect = (value, skuBatchList, state, stateUpdater, rateUpdater = () => null) => {
  const selectedBatch = skuBatchList.filter(({ id }) => id === value)[0];
  const usageDate = {
    manufacture: selectedBatch.batchDetails.usageDate
      ? selectedBatch.batchDetails.usageDate.manufacture
      : new Date().toISOString().slice(0, 10),
    expiry: selectedBatch.batchDetails.usageDate
      ? selectedBatch.batchDetails.usageDate.expiry
      : new Date().toISOString().slice(0, 10),
  };
  updateSkuUsageDates(state, stateUpdater, usageDate);
  rateUpdater(state, stateUpdater, selectedBatch);
};

const storeInLocalStorage = (key, data, domain) => {
  localStorage.setItem(`${domain}-${key}`, JSON.stringify(data));
};

const fetchFromLocalStorage = (key, domain) => JSON.parse(localStorage.getItem(`${domain}-${key}`));

const getPrintUrl = ({ module, schema, invoiceNumber, firstCopy, userId, invoiceHash }) => {
  if(module===DOMAIN.DBS_SALES)
    return `${schema}/sales/sales-invoice/details/${invoiceHash}/${invoiceNumber}/${userId}/${firstCopy}`;
  if(module===DOMAIN.DBS_SALES_RETURN)
    return `${schema}/sales/sales-return/details/${invoiceHash}/${invoiceNumber}/${userId}/${firstCopy}`;
  if(module===DOMAIN.DBS_PURCHASE_RETURN)
    return `${schema}/purchase/purchase-return/details/${invoiceHash}/${invoiceNumber}/${userId}/${firstCopy}`;
  if(module===DOMAIN.DBS_GRN)
    return `${schema}/purchase/purchase-invoice/details/${invoiceHash}/${invoiceNumber}/${userId}/${firstCopy}`;
  if (module === DOMAIN.SALES)
    return `${schema}/sales-invoice-print-details/${invoiceHash}/${invoiceNumber}/${userId}/${firstCopy}`;
  if (module === DOMAIN.ABBREVIATED_INVOICE)
    return `${schema}/abbreviated-invoice-print-details/${invoiceHash}/${invoiceNumber}/${userId}/${firstCopy}`;
  if (module === DOMAIN.PURCHASE_RETURN)
    return `${schema}/purchase-return-print-details/${invoiceHash}/${invoiceNumber}/${userId}`;
  return `${schema}/sales-return-print-details/${invoiceHash}/${invoiceNumber}/${userId}`;
};

const handlePrint = (data = { distributorServices: {} }, callBack = () => null) => {
  const invoiceNumber = voucherEncoder(data.invoiceNumber);
  const schema = data.distributorServices.billing.status && data.distributorServices.billing.url;
  const userId = getUserBillingCredentials().userId || 1;
  const invoiceHash = voucherEncoder(encryptText(data.invoiceNumber, invoiceNumber));
  const url = getPrintUrl({
    ...data,
    schema,
    invoiceNumber,
    userId,
    invoiceHash,
  });
  newWindow(url);
  callBack();
};

const getDistributorServiceStatus = (distributor, service) =>
  (distributor.servicesUsed && distributor.servicesUsed[service].status) || false;

const getDistributorFilteredViewList = (userInfo, viewList = VIEW_LIST) => {
  if (userInfo.Distributor && userInfo.Distributor.length > 0) {
    return viewList.filter((item) => item.label !== COVERAGE_TYPE.SUB_D);
  }

  return viewList;
};

const controlFilterDisplay = (filterConfig) => {
  if (!isObjectEmpty(filterConfig.menu)) {
    return true;
  }
  return false;
};

const headerLabelConfig = (user) => {
  if (JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.LABEL.HEADER))) {
    return JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.LABEL.HEADER))[user];
  }
};

const getSortValueAfterValueCheck = (sort) =>
  sort.order && sort.order !== SORTING.NO_SORT ? [{ sortBy: sort.label, order: sort.order }] : {};

const getFromLocalStorage = (key) => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
};

const getSubdUserStatusAndId = () => {
  const user = getUser();
  const subD = { distributorExist: false, distributorId: 0 };
  if (user.Distributor && user.Distributor.length === 1) {
    subD.exist = true;
    subD.id = user.Distributor[0].id;
  }
  return subD;
};

const generateRequestMessage = (status) => {
  if (status === 'APPROVED') {
    return 'Successfully Approved';
  }
  return 'Successfully Rejected';
};

const updateCustomerList = (outletId, distributorServices, outletList, invoiceNumberStatus, paymentMode) => {
  const selectedOutlet = outletList.find((outlet) => outlet.id === outletId);
  if (selectedOutlet) {
    const customerList = selectedOutlet.Customers || [];
    return customerList;
  }
};

const userSpecificTitle = (user) => {
  switch (user) {
  case 'dse':
    return headerLabelConfig(USER_ROLE_TITLE.DSE) || user.toUpperCase();
  case 'stl':
    return headerLabelConfig(USER_ROLE_TITLE.STL) || user.toUpperCase();
  case 'asm':
    return headerLabelConfig(USER_ROLE_TITLE.ASM) || user.toUpperCase();
  case 'name':
    return headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE';
  case 'delivery':
    return 'Delivery (MTD)' || user.toUpperCase();
  case 'calls':
    return 'Calls (MTD)' || user.toUpperCase();
  case 'tasUsers':
    return 'TAS User' || user.toUpperCase();
  case 'tas':
    return headerLabelConfig(USER_ROLE_TITLE.TAS) || user.toUpperCase();
  default:
    return user;
  }
};

const getUpdatedLabel = (labelConfig, secondaryLabel = '') => {
  const updatedLabelConfig = labelConfig.map((l) => {
    if (l.isDynamic) {
      l.title = `${userSpecificTitle(`${l.label}`)} ${secondaryLabel}`;
    }
    return l;
  });
  return updatedLabelConfig;
};

const getFileType = (typeOfFile) => {
  switch (typeOfFile) {
  case 'jpg':
    return '/image/icons/image-icon.svg';
  case 'png':
    return '/image/icons/image-icon.svg';
  case 'jpeg':
    return '/image/icons/image-icon.svg';
  case 'csv':
    return '/image/icons/excel.svg';
  case 'mp4':
    return '/image/icons/movie.svg';
  case 'pdf':
    return '/image/icons/pdf.svg';
  case 'xls':
    return '/image/icons/excel.svg';
  default:
    return '/image/icons/user.svg';
  }
};

const groupBy = (list, property) =>
  list.reduce((acc, x) => {
    let key = x[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(x);
    return acc;
  }, {});

const contactVerified = (isVerified) => {
  switch (isVerified) {
  case true:
    return '/image/icons/VerifiedIcon.svg';
  case false:
    return '/image/icons/contact.svg';
  default:
    return '';
  }
}

export {
  isConfirmationType,
  getCheckboxStatus,
  getFlattenedOrders,
  isError,
  errorMessageParser,
  imageSliderListFormatter,
  updateSkuUsageDates,
  handleSkuBatchSelect,
  storeInLocalStorage,
  fetchFromLocalStorage,
  getAllIds,
  imageSliderListFormatterWithSrc,
  handlePrint,
  getDistributorServiceStatus,
  controlFilterDisplay,
  headerLabelConfig,
  getDistributorFilteredViewList,
  getSortValueAfterValueCheck,
  getFromLocalStorage,
  handleGRNSKUBatchSelect,
  handleSRNSkuBatchSelect,
  getSubdUserStatusAndId,
  generateRequestMessage,
  updateCustomerList,
  userSpecificTitle,
  getUpdatedLabel,
  getFileType,
  groupBy,
  contactVerified,
};
