import React from 'react';
import PropTypes from 'prop-types';
import { ImageColumn } from '../../../../utils/tableUtils';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import {
  menuAction, tableHeaderWithCheckBox, CheckBox
} from '../../../../v4/components';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  alias: 'alias',
  images: 'images',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.alias,
    title: 'Alias',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.images,
    title: 'Images',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const returnTableBody = (
  data,
  checkedList,
  handleSliderOpen,
  onIconClick,
  onActionClick,
  onSecondaryCheckBoxClick,
  permission,
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`${data.id}-checkbox`} onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data.title}</td>;
  table[labelMappings.alias] = <td key={`${data.id}-alias`}>{data.alias}</td>;
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-images-${data.id}`}
      imageList={data.images || []}
      onImageClick={handleSliderOpen}
      key={`${data.id}-image`}
    />
  );
  table[labelMappings.actionHolders] = (
    <td key={`${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {permission.delete&&menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onActionClick, data, permission)}
    </td>
  );
  return table;
};

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

const TableBody = ({
  data,
  handleSliderOpen,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission,
  onActionClick,
}) => {
  const tableBody = returnTableBody(
    data,
    checkedList,
    handleSliderOpen,
    onIconClick,
    onActionClick,
    secondaryCheckboxHandler,
    permission,
  );
  return (
    <tr key={`${data.id}-tr`} style={{ cursor: 'pointer' }} onClick={() => onIconClick(EVENT_OPERATION.DETAILS, data)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export {
  TableHeader, TableBody, labelConfig
};
