import { getTodaysDate } from '../../../../utils/date';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { customerMapper } from './summaryDetails/config';
import { STOCK_TYPE } from '../../../common/DomainConfig';
import { SRN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import LEDGERS from '../../../../data/enums/Ledgers';
import { VAT_AMOUNT } from '../../../../data/enums/GeneralConstants';

const title = 'Create SRN';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'SRN',
    path: `/${SRN}`,
    active: true,
  },
];

const summaryDetailsMapper = (element, distributorId = 0) => ({
  id: element.id || '',
  date: element.date || getTodaysDate(),
  salesInvoiceNumber: element.invoiceNumber || '',
  salesReturnInvoiceNumber: element.salesReturnInvoiceNumber || '',
  Customer: customerMapper(element.Customer || {}),
  RetailOutlet: {
    id: element.RetailOutlet ? element.RetailOutlet.id : '',
    title: element.RetailOutlet ? element.RetailOutlet.title : '',
    Customers: element.RetailOutlet ? element.RetailOutlet.Customers : [],
    townId: element.RetailOutlet ? element.RetailOutlet.townId : '',
  },
  Distributor: { id: element.Distributor ? element.Distributor.id || 0 : distributorId },
  reason: element.reason || '',
  cashStatus: element.paymentMode === LEDGERS.CASH.title ? true : false || false,
  lineId: element.lineId || null,
  srnDate: element.srnDate || getTodaysDate(),
  paymentMode: element.paymentMode || null,
  customerName: element.customerName || '',
  invoiceDate: element.invoiceDate || getTodaysDate(),
});

const orderFormatter = (orders = []) => {
  let totalValidOrders = 0;
  const updatedOrders = orders.reduce((orderAcc, order) => {
    const Lines = order.Lines.reduce((acc, item) => {
      item.batchName = item.SKUBatch.batchName;
      if (item.updatedQuantity) {
        item.returnQuantity = item.updatedQuantity || 0;
        item.stockType = STOCK_TYPE.SALEABLE.value;
        item.updatedAmountDetails = item.updatedAmountDetails || {};
        totalValidOrders += 1;
        acc.push(item);
      }

      return acc;
    }, []);
    if (Lines.length > 0) {
      orderAcc.push({ ...order, Lines });
    }
    return orderAcc;
  }, []);

  return { orders: [...updatedOrders], totalValidOrders };
};

const totalPriceMapper = (element) => ({
  billDiscount: element.billDiscount || 0,
  tradeDiscount: element.tradeDiscount || 0,
  promotionDiscount: element.promotionDiscount || 0,
  discountAmount: element.discountAmount || 0,
  topUpDiscount: element.topUpDiscount || 0,
  grossAmount: element.grossAmount || 0,
  subTotal: element.subTotal || 0,
  taxAmount: element.taxAmount || 0,
  taxableAmount: element.taxableAmount || 0,
  netAmount: element.netAmount || 0,
});

const detailsFormConfig = {
  [FORM_CONFIG.MAPPER]: (element) => summaryDetailsMapper(element),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPDATE} SRN`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['outletId', 'reason']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const formMapper = (element, distributorId) => {
  const orderDetails = element.Orders
    ? orderFormatter(element.Orders) : { orders: [], totalValidOrders: 0 };

  return {
    ...summaryDetailsMapper(element, distributorId),
    uuid: element.uuid || '',
    orders: orderDetails.orders || [],
    totalValidOrders: orderDetails.totalValidOrders || 0,
    amount: totalPriceMapper(element.amountDetails ? element.amountDetails || {} : {}),
    billDiscountDetails: element.billDiscountDetail ? element.billDiscountDetail || [] : [],
  };
};

const calculateRelativeDiscount = (referenceAmount = 1, referenceDiscount, amount) =>
  (amount * referenceDiscount) / referenceAmount;

const getFormattedSkuLines = (list, oldBillStatus, totalList) => (
  list.map((item) => {
    const { amount, discountValue, discountTypeId , ...srnLineAmount } = item.updatedAmountDetails;
    const taxableAmt = srnLineAmount.subTotal - srnLineAmount.promotionDiscount - srnLineAmount.topUpDiscount - calculateRelativeDiscount(totalList.subTotal,Number(totalList.billDiscount), srnLineAmount.subTotal) - calculateRelativeDiscount(totalList.subTotal,Number(totalList.tradeDiscount), srnLineAmount.subTotal)
    return {
      batchName: item.batchName,
      orderId: oldBillStatus ? null : item.id,
      skuId: item.skuId,
      skuBatchId: item.skuBatchId,
      stockType: item.stockType,
      returnQuantity: item.returnQuantity,
      quantity: item.updatedQuantity,
      priceDetails: {
        rate: Number(item.updatedAmountDetails.rate),
        discount: Number(item.updatedAmountDetails.promotionDiscount),
        amount: item.returnQuantity * Number(item.updatedAmountDetails.rate),
        netAmount: item.returnQuantity * Number(item.updatedAmountDetails.rate) - Number(item.updatedAmountDetails.promotionDiscount),
      },
      manufactureDate: item.manufactureDate,
      expiryDate: item.expiryDate,
      srnLineAmount: {
        ...srnLineAmount,
        billDiscount:calculateRelativeDiscount(totalList.subTotal,Number(totalList.billDiscount), srnLineAmount.subTotal),
        tradeDiscount:calculateRelativeDiscount(totalList.subTotal,Number(totalList.tradeDiscount), srnLineAmount.subTotal),
        taxAmount: 0.13 * taxableAmt,
        taxableAmount: taxableAmt,
        grossAmount:srnLineAmount.rate*item.returnQuantity* (1 + (VAT_AMOUNT || 0)),
        netAmount: taxableAmt + 0.13 * taxableAmt,
        rate: Number(srnLineAmount.rate),
        discountAmount: Number(srnLineAmount.discountAmount),
        promotionDiscount: Number(srnLineAmount.promotionDiscount),
      },
    };
  }));

const getPayload = (data) => ({
  invoiceNumber: data.salesInvoiceNumber,
  srnInvoiceNumber: data.srnInvoiceNumber,
  reason: data.reason,
  distributorId: data.Distributor.id,
  retailOutletId: data.RetailOutlet.id,
  customerId: data.Customer ? data.Customer.id : null,
  srnDate: data.srnDate,
  lineId: data.lineId,
  paymentMode: data.cashStatus === true ? LEDGERS.CASH.title : 'CUSTOMER',
  customerName: data.customerName || '',
  srnLines: getFormattedSkuLines(data.returnOrders, data.oldBillStatus,data.amount),
  srnAmount: {
    ...data.amount,
    discountAmount: Number(data.amount.discountAmount),
    promotionDiscount: Number(data.amount.promotionDiscount),
    billDiscount: Number(data.amount.billDiscount),
    tradeDiscount: Number(data.amount.tradeDiscount),
  },
  isOldBill: data.oldBillStatus,
  type: data.srnType,
});

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({ input: getPayload(data) }),
    responseName: 'returnSales',
    message: `SRN ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      input: getPayload(data),
    }),
    responseName: 'updateSrn',
    message: `SRN ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.APPROVE]: {
    objectMapper: (data) => ({
      id: data.id,
      input: getPayload(data),
    }),
    responseName: 'approveSrn',
    message: `SRN ${MESSAGE_EVENT_OPERATION.APPROVE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({ id: data.id }),
    responseName: 'cancelSrn',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
};

export {
  crudSuccess,
  breadCrumb as breadCrumbConfig,
  title,
  detailsFormConfig,
  formMapper,
  summaryDetailsMapper,
  totalPriceMapper,
  getPayload,
};
