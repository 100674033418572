import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { has } from '../objectPrototypes';
import { getDisplayName } from './component';
import { FORM_CONFIG } from '../../data/enums/config';
import { clone, compareList } from '../arrayProcessor';
import { debouncer, dropdownChange, inputChange, radioChange } from '../formHandlers';

// assumed each mapper is mounted on every change.
// it mapper config change directly w/o mounting use derive state from props.

export default function withFormWrapper(SourceComponent) {
  const propTypes = {
    type: PropTypes.string,
    onDialogSubmit: PropTypes.func,
    onDialogCancel: PropTypes.func,
    dialogElement: PropTypes.instanceOf(Object),
    renderDialog: PropTypes.func,
    formConfig: PropTypes.shape({
      title: PropTypes.string,
      mapper: PropTypes.func,
      refsObj: PropTypes.instanceOf(Object),
      validationRequired: PropTypes.bool,
    }),
    controlDialogClose: PropTypes.bool,
    disableDialogClose: PropTypes.bool,
  };

  const defaultProps = {
    type: '',
    onDialogSubmit: () => null,
    onDialogCancel: () => null,
    dialogElement: {},
    renderDialog: () => null,
    formConfig: PropTypes.shape({
      mapper: () => null,
      title: '',
      refsObj: {},
      validationRequired: PropTypes.bool,
    }),
    controlDialogClose: false,
    disableDialogClose: false,
  };

  class FormWrapper extends Component {
    constructor(props) {
      super(props);
      const { formConfig, dialogElement } = props;
      this.state = {
        dialogElementBackup: props.dialogElement,
        details: has.call(formConfig, FORM_CONFIG.MAPPER) ? formConfig[FORM_CONFIG.MAPPER](dialogElement) : {},
        enableErrorDisplay: false,
      };
      const k = this.state;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { dialogElementBackup } = prevState;
      const { dialogElement, formConfig } = nextProps;
      if (!compareList(dialogElement, dialogElementBackup)) {
        return {
          dialogElementBackup: dialogElement,
          details: has.call(formConfig, FORM_CONFIG.MAPPER) ? formConfig[FORM_CONFIG.MAPPER](dialogElement) : {},
        };
      }
      return null;
    }

    getFormValidationStatus = () => {
      const {
        formConfig: { refsObj },
      } = this.props;
      return !Object.values(refsObj).find(item => {
        if (typeof item !== 'string') return item?.getValidState() === false;
      });
    };

    handleFormSubmit = () => {
      const {
        onDialogSubmit,
        type,
        onDialogCancel,
        controlDialogClose,
        popUpGrn,
        displayAlert,
        approveGrn,
        extraValidation,
        disableDialogClose
      } = this.props;
      const { details } = this.state;
      const valid = this.getFormValidationStatus();
      let totalQuantity;
      if (popUpGrn) {
        totalQuantity =
          details.warehouseLevelDetails.map(d => d.quantity).length > 0 &&
          details.warehouseLevelDetails.map(d => d.quantity).reduce((a, b) => a + b);
      }
      if (
        popUpGrn &&
        details.warehouseLevelDetails.map(d => d.quantity).length > 0 &&
        totalQuantity !== details.quantity
      ) {
        onDialogSubmit(type, details, 'error');
        this.setState({ enableErrorDisplay: true });
      } else if (approveGrn && details.shortages + details.damages > details.quantity) {
        onDialogSubmit(type, details, 'qtyError');
        this.setState({ enableErrorDisplay: true });
      } else if (extraValidation) {
        onDialogSubmit(type, details, 'error');
        this.setState({ enableErrorDisplay: true });
      } else if (valid) {
        onDialogSubmit(type, details);
        if (!controlDialogClose && !disableDialogClose) {
          onDialogCancel();
        }
      } else {
        this.setState({ enableErrorDisplay: true });
      }
    };

    // event: event received from dom
    // firstParam: first Level Parameters
    // paramList: hierarchy in nestedObject : ['name', 'children', 'value']
    handleInputChange = (event, firstParam = '', paramList = [], callBack = () => null,param) => {
      const { details } = this.state;
      const updatedDetails = inputChange(details, event, firstParam, paramList,param);
      this.setState({ details: updatedDetails }, () => {
        debouncer(callBack, 760)(
          paramList[paramList.length - 1 || 0],
          event.formattedValue,
          clone(this.state.details),
          this.updateState,
          paramList
        );
      });
    };

    handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
      const { details } = this.state;
      const updatedDetails = dropdownChange(details, parameterRef, value);
      this.setState({ details: updatedDetails }, () => {
        callBack(parameterRef[parameterRef.length - 1], value, clone(this.state.details), this.updateState);
      });
    };

    handleDateChange = (event, firstParam = '', paramList = [], callBack = () => null) => {
      const { details } = this.state;
      details[firstParam] = event;
      this.setState({ details }, () => {
        debouncer(callBack, 760)(
          paramList[paramList.length - 1 || 0],
          event.formattedValue,
          clone(this.state.details),
          this.updateState,
        );
      });
    };

    handleRadioButtonChange = (e,paramList = [], callBack = () => null, param,completecall=null) => {
      const { details } = this.state;
      const { name, value } = e.target;
      if(paramList.length>0){
        const updatedDetails = radioChange(details, paramList, value);
        this.setState({ details: updatedDetails }, () => {
          callBack(param, value, clone(this.state.details), this.updateState, paramList);
        });
      }else{
        if(details.hasOwnProperty('completecall')){
          this.setState({
            details: {
              ...details,
              ...{
                [name]: param==='noLowerCase'?value:value.toLowerCase(),
                },
                ...{
                  completecall: completecall,
                  },

               
              },
            }, () => {
              callBack(paramList[paramList.length - 1]||param, value, clone(this.state.details), this.updateState);
            });
          } 
          else{
      this.setState({
        details: {
          ...details,
          ...{
            [name]: param==='noLowerCase'?value:value.toLowerCase(),
            }
           
          },
        }, () => {
          callBack(paramList[paramList.length - 1]||param, value, clone(this.state.details), this.updateState);
        });
      }
       
      }
    };

    updateState = details => {
      this.setState({ details });
    };

    getState = () => {
      const { details } = this.state;

      return clone(details);
    };

    handleFileUpload = e => {
      const { details } = this.state;
      details.file = e.target.files[0];
      this.setState({ details });
    };

    handleMultipleUpload = (e, uploadCallBack) => {
      const { details } = this.state;
      uploadCallBack(e.target.files[0], clone(details), this.updateState);
    };

    render() {
      const { details, enableErrorDisplay } = this.state;
      const {
        handleInputChange,
        handleDropDownChange,
        handleFileUpload,
        getFormValidationStatus,
        handleFormSubmit,
        handleMultipleUpload,
        updateState,
        getState,
        handleDateChange,
        handleRadioButtonChange,
      } = this;
      const newProps = {
        handleInputChange,
        handleDropDownChange,
        handleFileUpload,
        getFormValidationStatus,
        dialogData: details,
        enableErrorDisplay,
        handleFormSubmit,
        handleMultipleUpload,
        updateState,
        getState,
        handleDateChange,
        handleRadioButtonChange,
        ...this.props,
      };

      return (
        <Fragment>
          <SourceComponent {...newProps} />
        </Fragment>
      );
    }
  }

  FormWrapper.displayName = `withAlert(${getDisplayName(SourceComponent)})`;

  FormWrapper.propTypes = propTypes;

  FormWrapper.defaultProps = defaultProps;

  return FormWrapper;
}
