/* eslint-disable no-case-declarations */
/* eslint-disable indent */
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react'
import { CALL_PLAN, CALL_PLAN_BASE } from '../../../../data/enums/Route'
import withAlert from '../../../../utils/composition/withAlert'
import withLoading from '../../../../utils/composition/withLoading'
import {
  BreadCrumb, Button, Icon, ImageSlider, Tab, TabList, TabPanel, Tabs
} from '../../../../v4/components'
import { ALERT_TYPE } from '../../../../v4/constants/AlertType'
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation'
import PageHeader from '../../../base/PageHeader'
import { DialogFormWrapper } from '../../../common'
import { PanelStyled } from '../../../common/configuration'
import { CALLPLAN_TABS } from '../config'
import {
  CallPlanContainer, DialogContainer, OutletDetailsContainer, SKUDetailsContainer
} from './CallPlanDetails.styled'
import CallPlanDrawer from './callPlanDrawer'
import {
  BrandEnrollment, CallHistory, OutletDetails, OutstandingOverdue, ScheduleCall
} from './components'
import NoOrderForm from './components/NoOrderForm'
import SKUForm from './components/SKUForm'
import Timer from './components/Timer'
import {
 formConfig, OPERATION ,ACTIVE_TAB
 } from './config'
import OrderPadDrawer from './orderPadDrawer'
import SKUTab from './tabs'
import history from '../../../../utils/history';
import BillingDeliveryPanel from './billingDelivery'
import { parseDateByFormat } from '../../../../utils/date'
import { imageSliderListFormatter, isError } from '../../../common/HelperFunctions'
import MultiplePromotionForm from './components/MultiplePromotionForm'
import { splitFilteredArray } from '../../../configuration/arc/utils/arrayUtil'
import CompletedOrders from './completedOrders'
import { JWT } from '../../../../environment'

// const skuNavs = [{
//   title: 'Focused SKUs',
//   onClick: (event) => { event.preventDefault() },
// }, {
//   title: 'SBD SKUs',
//   onClick: (event) => { event.preventDefault() },
// }, {
//   title: 'Brands SKUs',
//   onClick: (event) => { event.preventDefault() },
// }]


const CallPlanDetails = (props) => {
  const id = props.match.params.id;
  const { state = {} } = props?.location;
  const [data, setData] = useState({ skus: [] });
  const [orderPadDetail, setorderPadDetail] = useState([]);
  const breadCrumbConfig = [
    {
      id: 0,
      title: CALL_PLAN_BASE,
      path: `/${CALL_PLAN}`,
      active: false,
    },
    {
      id: 1,
      title: data?.callPlanBasic?.planstatus?.toLowerCase() || state?.callPlanStatus || 'Pending',
      path: '#',
      active: false,
    }
  ];
  const [showSummaryOverlay, setShowSummaryOverlay] = useState(false);
  const [showBillingDeliveryPanel, setShowBillingDeliveryPanel] = useState(false);
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [startCall, setStartCall] = useState(false);
  const [callNotes, setCallNotes] = useState('');
  const [noOrder, setNoOrder] = useState([]);
  const [tasValidateSet,setTasValidateSet] =useState({});
  const [editGroupPromotion, seteditGroupPromotion] = useState([])
  const [oldData,setoldData] = useState([])
  const [newOrderData, setnewOrderData] = useState([])
  let finalDataToCalculateDiscounts = [];
  const [groupSKU, setGroupSKU] = useState([]);

  const callSummary = useRef({
    start: '',
    end: ''
  });
  let timer;
  const crudMode = (props.match.params.id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE);
  const summaryRef = useRef({
    totalLines: 0,
    netAmount: 0
  })
  const skuSummaryDetails = useRef([]);
  const callPlanPayload = useRef({})

  const closeOverlay = () => setShowSummaryOverlay(false);
  const toggleOverlay = () => setShowSummaryOverlay(!showSummaryOverlay);

  const editCallNotes = (event) => {
    setCallNotes(event.target.value);
  }
  const onTabChange = (tabIndex) => {
     setActiveTab(tabIndex);
  };

  const filterData = (skuData) => {
    if (!skuData) return [];
    const isPlannedOrCompleted = ['planned', 'completed'].includes(data?.callPlanBasic?.planstatus?.toLowerCase() || state?.callPlanStatus || 'Pending');
    if (isPlannedOrCompleted) {
      /**
       * Need to display sku having pfdTarget and quantity in Call Analysis dialog
       */
      return skuData.filter((sku) => sku?.quantity > 0 || sku?.pfdtarget > 0) || [];
    }

    /**
     * Fixes: Call Plan Drawer, while erasing call-target input it gets removed from the list.
     * sku?.callTarget === '' prevents it.
     */
    return skuData.filter((sku) => sku?.callTarget > 0 || sku?.callTarget === '') || [];
  }

  useMemo(() => {
    const isPlannedOrCompleted = ['planned', 'completed'].includes(data?.callPlanBasic?.planstatus?.toLowerCase() || state?.callPlanStatus || 'Pending');
    const isCompleted = ['completed'].includes(data?.callPlanBasic?.planstatus?.toLowerCase() || state?.callPlanStatus || 'Pending');
    const filteredSkus = filterData(data?.skus);

    /**
     * Start filtering out skus based on flag introduced
     * Used grouping of skus for CallPlanDrawer and OrderPadDrawer.
     * If topSkuSummary already contains a SBD sku 'A' then it will be already filtered out, sbdSKUSummary wont contain sku 'A'
     */
    const { filtered: topSkuSummary, remaining: filteredTopSku } = splitFilteredArray(filteredSkus, (sku) => sku.isTopSku);
    const { filtered: recommendedSkuSummary, remaining: filteredRecommendedSku } = splitFilteredArray(filteredTopSku, (sku) => sku.isRecommendedSku);
    const { filtered: sbdSkuSummary, remaining: filteredSbdSku } = splitFilteredArray(filteredRecommendedSku, (sku) => sku.isSBDSku);
    const { filtered: focusedSkuSummary, remaining: otherSkuSummary } = splitFilteredArray(filteredSbdSku, (sku) => sku.isFocusedSku);

    /**
     * skuSummaryDetails used for following with some mainpulation:
     * Call Plan Drawer
     * Order Pad
     * Call Analysis
     */
    skuSummaryDetails.current = [
      {
        title: 'Top 10 SKUs',
        skus: topSkuSummary,
        label: 'top-skus'
      },
      {
        title: 'Recommended SKUs',
        skus: recommendedSkuSummary,
        label: 'recommended-skus'
      },
      {
        title: 'SBD SKUs',
        skus: sbdSkuSummary,
        label: 'sbd-skus'
      },
      {
        title: 'Focused SKUs',
        skus: focusedSkuSummary,
        label: 'focused-skus'
      },
      {
        title: 'Other SKUs',
        skus: otherSkuSummary,
        label: 'skus'
      },
    ];

    if (isCompleted) {
      summaryRef.current.callOrderValue = {
        // eslint-disable-next-line no-undefined
        netAmount: undefined,
        // eslint-disable-next-line no-undefined
        discountAmount: undefined
      }
    }

    if (isPlannedOrCompleted) {
      summaryRef.current.sbdSkuLines = filteredSkus.filter((sku) => sku.isSBDSku && sku.quantity > 0).length;
    } else {
      summaryRef.current.sbdSkuLines = filteredSkus.filter((sku) => sku.isSBDSku && sku.callTarget > 0).length;
    }

    summaryRef.current.totalLines = filteredSkus.filter((sku) => isPlannedOrCompleted ? sku?.quantity > 0 : sku?.callTarget > 0).length;
    summaryRef.current.netAmount = filteredSkus.reduce((total, currItem) => {
      total += (Number(isPlannedOrCompleted ? currItem?.pfdtarget || 0 : currItem?.callTarget || 0) * currItem?.Rates?.[0]?.priceDetails?.rlp)

      if (isCompleted) {
        if (!summaryRef?.current?.callOrderValue?.netAmount) {
          summaryRef.current.callOrderValue.netAmount = currItem?.call_order_value?.netAmount

        }

        if (!summaryRef?.current?.callOrderValue?.discountAmount) {
          summaryRef.current.callOrderValue.discountAmount = currItem?.call_order_value?.discountAmount;
        }
      }

      return total;
    }, 0)

    /**
     * Payload for saving call plan.
     */
    callPlanPayload.current = {
      retailOutletId: data?.outletBasic?.id,
      assignedUserId: data?.outletBasic?.dseId,
      callTarget: summaryRef.current.netAmount,
      sbds: sbdSkuSummary.filter((sku) => sku.callTarget > 0).map((sku) => ({ id: sku.id, quantity: Number(sku.callTarget) })),
      focusedSKUs: focusedSkuSummary.filter((sku) => sku.callTarget > 0).map((sku) => ({ id: sku.id, quantity: Number(sku.callTarget) })),
      p3mSKUs: topSkuSummary.filter((sku) => sku.callTarget > 0).map((sku) => ({ id: sku.id, quantity: Number(sku.callTarget) })),
      brandSKUs: filteredSkus.filter((sku) => sku.callTarget > 0).map((sku) => ({ id: sku.id, quantity: Number(sku.callTarget) })),
      recommendedSKUs: recommendedSkuSummary.filter((sku) => sku.callTarget > 0).map((sku) => ({ id: sku.id, quantity: Number(sku.callTarget) })),
      pfdDate: data?.callPlanBasic?.callPlanScheduledTime,
      tas_user_call_plan_id: Number(id)
    }
  }, [data.skus, data.callPlanBasic]);

  useEffect(() => {
    const { getCallPlanOutletDetails, displayAlert } = props;
    getCallPlanOutletDetails({ callPlanId: Number(id) }, {
      handleSuccess: (response) => {
        if (response.data && response.data.getCallPlanDetail) {
          const {
            topskus: topSkus,
            recommendedSKUS: recommendedSkus,
            SBDs: sbdSkus,
            FocusedSKUs: focusedSkus,
            SKUs: otherSkus,
            tas_validation_settings,
            ...rest
          } = response.data.getCallPlanDetail;
          topSkus.map((sku) => {
            const topSku = otherSkus?.find((otherSku) => otherSku.id === sku.id)
            topSku.isTopSku = true;
          });

          recommendedSkus.map((sku) => {
            const recommendedSku = otherSkus?.find((otherSku) => otherSku.id === sku.id)
            recommendedSku.isRecommendedSku = true;
          });

          sbdSkus.map((sku) => {
            const sbdSku = otherSkus?.find((otherSku) => otherSku.id === sku.id)
            sbdSku.isSBDSku = true;
          });

          focusedSkus.map((sku) => {
            const focusedSku = otherSkus?.find((otherSku) => otherSku.id === sku.id)
            focusedSku.isFocusedSku = true;
          });

          setData({ ...rest, skus: otherSkus });
          setTasValidateSet(tas_validation_settings);
        }

        if (isError(response)) {
          displayAlert(ALERT_TYPE.DANGER, response?.errors?.[0]);
        }

      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    }
    )
  }, []);

  useEffect(() => {
    if (startCall) {
      timer = setInterval(() => {
        setSeconds(seconds + 1);
        if (seconds === 59) {
          setMinutes(minutes + 1);
          setSeconds(0);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  });

  const handleStartCall = () => {
    setStartCall(true);
    callSummary.current.start = parseDateByFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
  }

  const stopTimer = () => {
    clearInterval(timer);
    setStartCall(false);
    callSummary.current.end = parseDateByFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
  }

  const stopUnsuccessfulTimer = () => {
    setStartCall(false);
  }

  const handleCallScheduleChange = (value) => {
    setData({ ...data, callPlanBasic: { callPlanScheduledTime: value } })
  };


  const handleCallTargetChange = useCallback((id, value, deleteRefIfEmpty = true) => {
    if (deleteRefIfEmpty) {
      value = value === '' ? null : value;
    }

    const skuCallTarget = data?.skus?.find((sku) => sku.id === id);
    if (skuCallTarget)
      skuCallTarget.callTarget = value;

    setData({ ...data, skus: [...data.skus] });
  }, [data]);

  const handleSaveCallTarget = () => {
    const { savePlansForTas, displayAlert } = props;
    const SBD_COUNT=`Please set call target for at least ${tasValidateSet?.sbdcount || 2} SBD SKUs.`;
    if (data?.validation_required) {
      if (summaryRef.current.sbdSkuLines < tasValidateSet?.sbdcount) {
        return displayAlert(ALERT_TYPE.CUSTOM_DANGER, SBD_COUNT);
      }
      if (summaryRef.current.netAmount < data?.avg_sales_per_bill) {
        return displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Call Target is less than Average sales per Bill.')
      }
    } else {
      if (summaryRef.current.totalLines === 0) {
        return displayAlert(ALERT_TYPE.WARNING, 'You need to set call target first!');
      }
    }
    savePlansForTas(callPlanPayload.current, {
      handleSuccess: (response) => {
        if (response.data && response.data.SavePlansForTAS) {
          displayAlert(ALERT_TYPE.SUCCESS, 'Call Planned Successfully.')
          setTimeout(() => {
            history.push({
              pathname: `/${CALL_PLAN}`,
              state: { plannedTab: 1 }
            })
          }, 2000);
        }
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    }
    )
  }

  const handleRowClick = useCallback((type, element = {}) => {
    setDialog({
      type,
      element: {
        ...element,
      },
    });
  }, [dialog.type]);

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
  };

  const handleEditPromotion = (type, element = {}, remainingData, promotionId) => {

    const changedPromotionSkus = element.filter((x)=>x.promotionId===promotionId)?.[0]?.element
    setDialog({
      type,
      element: {
        promotionSkus: changedPromotionSkus,
        promotionTitle: changedPromotionSkus?.title,
        promotion: remainingData || [],
        promotionId: changedPromotionSkus?.[0]?.promotionId,
        freeSku: changedPromotionSkus?.[0]?.freeSku,
      }
    })
  }

  const handlePromotionClick = (type, element = {}, tabLabel, remainingData) => {
    const { getpromotionalskus, displayAlert } = props;
    getpromotionalskus({ promotionId: element.id,distributorId: callPlanBasic?.distributorId }, {
      handleSuccess: (response) => {
        const promotionSKUS = response?.data?.getPromotionSkus;
        const changedPromotionSKUS = [{
          promotionId: element.id,
          promotionSKUS,
        }]

        editGroupPromotion?.map((x)=>
        x.element?.map((promotion) => {
          const promotionIndex = promotionSKUS.findIndex((a) => a.id === promotion.id);
          if (promotionIndex > -1) {
            promotionSKUS[promotionIndex].quantity = promotion.quantity
            return
          }
          changedPromotionSKUS.push({ promotionId: element.id,promotionSKUS })
        }))
        const filteredPromotionSKUS = changedPromotionSKUS.find((x)=>x.promotionId===element.id)?.promotionSKUS;
        setDialog({
          type,
          element: {
            promotionSkus: filteredPromotionSKUS?.map((sku) => {
              if(sku.id===remainingData?.id){
                sku.quantity= remainingData?.quantity||sku.quantity }
              if (sku.image) {
                sku.image = [
                  ...(imageSliderListFormatter(sku.image) || []),
                ];
              }
              return sku;
            }),
            promotionTitle: element.title,
            promotionId: element.id,
            tabLabel,
            remainingData,
          }
        })
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    })
  }

  const handleQntyChange = (item, value, initialstate, stateUpdater) => {
    const { calculatePromotion, displayAlert } = props;
    const input = {
      skuId: initialstate.id,
      familyId: initialstate.familyId,
      rateDetails: {
        rlp: initialstate.Rates?.[0]?.priceDetails?.rlp
      },
      quantity: value,
      promotionDiscount: 0,
      topUpDiscount: 0,
      billDiscount: 0,
      promotions: [],
      skuBatchRateId: initialstate.Rates?.[0]?.id,
    }

    calculatePromotion({ input, categoryId: outletBasic?.categoryId, distributorId: callPlanBasic?.distributorId, callPlanId: Number(id) }, {
      handleSuccess: (response) => {
        const responseData = response.data && response.data.calculatePromotionDiscounts?.normalPromotionAppilied?.[0];
        const promotions = initialstate?.promotion?.map((promotion) => {
          const resData = responseData?.promotions?.find((initialPromotion) => (initialPromotion.id === promotion.id))
          if (resData) {
            promotion.status = true;
            promotion.freeSkus = resData.freeSkus?.map((a) => ({
              ...a,
              selected: false,
            }));
            return promotion;
          }
          promotion.status = false;
          promotion.freeSkus = null;
          return promotion
        })
        initialstate.promotion = promotions;
        initialstate.netAmount = responseData.netAmount === 0 ? '-' : responseData.netAmount;
        initialstate.netSaving = (value * initialstate.Rates?.[0]?.priceDetails?.rlp) - (responseData.netAmount / 1.13)
        stateUpdater(initialstate);
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    })
  };

  const handleFreeSKU = (item, value, initialstate, stateUpdater, paramList) => {
    const { displayAlert }= props;
    const freeSKUs = initialstate.promotion[paramList[1]].freeSkus.filter((a)=>a.quantity>0);
      const sum = freeSKUs.map((a)=>a.quantity).reduce((acc, cur) => acc + cur, 0);
      if(sum > initialstate.promotion[paramList[1]].freeSkus[paramList[3]].maxQuantity){
        displayAlert(ALERT_TYPE.WARNING,`Sum of quantity must be equal to ${initialstate.promotion[paramList[1]].freeSkus[paramList[3]].maxQuantity}`)
      }

    initialstate.promotion[paramList[1]].freeSkus = initialstate.promotion[paramList[1]].freeSkus.map((a) => ({
      ...a,
      selected: a.quantity>0
    }))
    initialstate.promotion[paramList[1]].freeSkus[paramList[3]].selected = true;
    initialstate.freeSku = freeSKUs?.map((s) => ({
      billDiscount: 0,
      promotionDiscount: 0,
      topUpDiscount: 0,
      promotions: [],
      selected: true,
      quantity: s?.quantity,
      rateDetails: { rlp: s?.rateDetails?.rlp },
      skuBatchRateId: s?.skuBatchRateId,
      skuId: s?.skuId,
      familyId: s?.familyId,
      maxQuantity: s?.maxQuantity,
    }));
    initialstate.netSaving = freeSKUs?.map((a)=>a?.quantity*a?.rateDetails?.rlp * 1.13)?.reduce((acc, cur) => acc + cur, 0);

    stateUpdater(initialstate);
  }

  const handleMultipleFreeSKU = (item, value, initialstate, stateUpdater, paramList) => {
    const { displayAlert }= props;
      initialstate.freeSku[paramList[1]].selected = initialstate.freeSku[paramList[1]].quantity>0;
      const freeSKUs  = initialstate?.freeSku?.filter((a)=>a.quantity>0);
      const sum = freeSKUs.map((a)=>a.quantity).reduce((acc, cur) => acc + cur, 0);
      if(sum > initialstate.freeSku[paramList[1]].maxQuantity){
        displayAlert(ALERT_TYPE.WARNING,`Sum of quantity must be equal to ${initialstate.freeSku[paramList[1]].maxQuantity}`)
      }else{
        initialstate.freeSku = initialstate.freeSku.map((a) => ({
          ...a,
          quantity: a.quantity,
          selected: a.quantity>0
        }))
      }
    stateUpdater(initialstate);
  }

  // scope === 'GROUP' => multiple promotion next dialog
  const handleRadioCallback = (item, value, initialstate, stateUpdater) => {
    switch (value) {
      case 'noOfftake':
        initialstate.rlp = 0;
        initialstate.message = '';
        stateUpdater(initialstate);
        break;
      case 'marginIssue':
        initialstate.message = '';
        stateUpdater(initialstate);
        break;
      case 'Other':
        initialstate.rlp = 0;
        stateUpdater(initialstate);
        break;
      default:
        break;
    }
    if (value === 'noOfftake') {
      initialstate.rlp = 0;
      initialstate.message = '';
      stateUpdater(initialstate);
    }
    if (value === 'noOfftake') {
      initialstate.rlp = 0;
      initialstate.message = '';
      stateUpdater(initialstate);
    }
  }

  const ReConstructData= (newData,oldData)=>{

    const newDataWithNoFreeSkus = newData.filter((x)=> !x.freeSku)
    const newDataFreeSkus = newData.filter((x)=> x.freeSku)


    const oldDataWithNoFreeSkus = oldData.filter((x)=> !x.freeSku)
    let oldDataFreeSkus = oldData.filter((x)=> x.freeSku)

    const appiliedPromotions = newData[0]?.promotionData?.map((x)=>x.id);
    oldDataFreeSkus =   oldDataFreeSkus.filter((x)=>!appiliedPromotions?.includes(x.promotionId));



    let objectsToBeAdded = newDataWithNoFreeSkus.filter((o1) => !oldDataWithNoFreeSkus.some((o2) => o1.skuId === o2.skuId));
    let objectsTobeReplaced =  newDataWithNoFreeSkus.filter((o1) => oldDataWithNoFreeSkus.some((o2) => o1.skuId === o2.skuId));
    let asISObjects = oldDataWithNoFreeSkus.filter((o1) => !newDataWithNoFreeSkus.some((o2) => o1.skuId === o2.skuId));

    if(newData[0]?.promotionData?.length>0){

      const promotionId =  newData[0]?.promotionData?.filter((a)=>(a.status&&a.promotionScope==='GROUP'))?.[0]?.id;
      const isTrueObj = asISObjects?.filter((x)=>(x.promotionData?.[0].status))
      const isFalseObj = asISObjects?.filter((x)=>(x.promotionData?.[0].status===false))
      const asISObjectsWithoutPromotions = asISObjects?.filter((x)=>(!x.promotionData))

      asISObjects = isTrueObj.filter((x)=>(x.promotionData?.[0]?.id !== promotionId))
      asISObjects=[...asISObjects,...isFalseObj,...asISObjectsWithoutPromotions]

    }

    let freeskusToBeAdded = newDataFreeSkus.filter((o1) => !oldDataFreeSkus.some((o2) => o1.promotionId === o2.promotionId));
    let freeskusTobeReplaced =  newDataFreeSkus.filter((o1) => oldDataFreeSkus.some((o2) => o1.promotionId === o2.promotionId));
    let asISfreeskus = oldDataFreeSkus.filter((o1) => !newDataFreeSkus.some((o2) => o1.promotionId === o2.promotionId));



    let finalDataToCalculateDiscountsarray = [...objectsToBeAdded,...objectsTobeReplaced,...asISObjects,...freeskusToBeAdded,...freeskusTobeReplaced,...asISfreeskus]

// s
    finalDataToCalculateDiscounts = finalDataToCalculateDiscountsarray.map((a) => {return { ...a }})
    setoldData([...finalDataToCalculateDiscountsarray]);

  }

  const deleteData = (newData,oldData) => {

    const newDataWithNoFreeSkus = newData.filter((x)=> !x.freeSku)

    const promotionData = newData[0]?.promotionData
    const newDataFreeSkus = promotionData?.find((x)=>x.freeSkus?.length>0)?.freeSkus


    const oldDataWithNoFreeSkus = oldData.filter((x)=> !x.freeSku)
    const oldPromotionData = oldData[0]?.promotionData
    //let oldDataFreeSkus = oldPromotionData?.find((x)=>x.freeSkus?.length>0)?.freeSkus || oldData.filter((x)=> x.freeSku)
    let oldDataFreeSkus = oldData.filter((x)=> x.freeSku)

    const appiliedPromotions = newData[0]?.promotionData?.map((x)=>x.id);

    oldDataFreeSkus =   oldDataFreeSkus.filter((x)=>!appiliedPromotions.includes(x.promotionId));
    let asISObjects = oldDataWithNoFreeSkus.filter((o1) => !newDataWithNoFreeSkus.some((o2) => o1.skuId === o2.skuId));

    let asISfreeskus = oldDataFreeSkus.filter((o1) => !newDataFreeSkus?.some((o2) => o1.promotionId === o2.promotionId));



    let finalDataToCalculateDiscountsarray = [...asISObjects,...asISfreeskus]

// s
    finalDataToCalculateDiscounts = finalDataToCalculateDiscountsarray.map((a) => {return { ...a }})
    setoldData([...finalDataToCalculateDiscountsarray]);

  }

  const multiplePromotionFreeSKU = (input, initialstate, stateUpdater, withFreeSKU , dialogData, type) => {
    const { calculateAllPromotionDiscounts, displayAlert } = props;
    const filterSinglePromotion = input?.filter((f)=>!f.promotionSkus);
    // singlePromotion without freeSKU
    const singlePromotionInput = filterSinglePromotion?.map((a) => ({
      skuId: a.id || a.skuId,
      familyId: a?.skuFamily?.id || a.familyId,
      rateDetails: {
        rlp: a.Rates?.[0].priceDetails?.rlp || a.rateDetails?.rlp
      },
      skuBatchRateId: a.Rates?.[0].id || a.skuBatchRateId,
      freeSku: false,
      quantity: a.quantity || 0,
      promotions: [],
      promotionId: initialstate?.promotionId,
      billDiscount: 0,
      topUpDiscount: 0,
      promotionDiscount: 0,
    })) || [];
    finalDataToCalculateDiscounts.forEach(function(v){  delete v.promotionData });

    const updatedInput = withFreeSKU ? [...singlePromotionInput] : finalDataToCalculateDiscounts;
    calculateAllPromotionDiscounts({
      input: updatedInput,
      categoryId: outletBasic?.categoryId, distributorId: callPlanBasic?.distributorId, callPlanId: Number(id)
    }, {
      handleSuccess: (response) => {
        const resData = response.data?.calculateAllPromotionDiscounts;
        setorderPadDetail(resData);
        if (withFreeSKU) {
          // for quantity change in MultiplePromotion
          const freeSkus = resData?.orders?.[0]?.promotions?.[0]?.freeSkus?.map((a) => ({
            ...a,
            quantity:a.quantity,
          })) || [];
          initialstate.freeSku = freeSkus;
          stateUpdater(initialstate);
        } else {
          // for multiplePromotion freeSKU Add to Order
          summaryRef.current.callOrderValue = resData?.callOrderValue;
          summaryRef.current.orders = resData?.orders;
          summaryRef.current.callTarget = resData?.callTarget;
          // for delete set all quantity set to 0
          initialstate = initialstate.map((x)=>({
            ...x,
            quantity:0,
            promotion: x?.promotion?.map((y)=>({
              ...y,
              status:false,
              freeSkus:null,
            }))
          }))
          resData?.orders.map((order) => {
            if (order.freeSku === false) {
              const respSku = initialstate.find((sku) => sku.id === order.skuId);
              const selectedFreeSKU = order.promotions?.map((x)=>x.freeSkus?.filter((a)=>a.selected)).flat();
              const promotions = respSku.promotion?.map((promotion) => {
                return(
                {
                  ...promotion,
                  promotionType: order.promotions.find((a)=>a.id===promotion.id)?.promotionType,
                  status: order.promotions.filter((a)=>a.id===promotion.id)?.length > 0,
                }
              )})
              respSku.quantity = order?.quantity;
              respSku.netSaving = order?.amountDetails?.netSaving || selectedFreeSKU?.map((a)=>a?.quantity*a?.rateDetails?.rlp * 1.13)?.reduce((acc, cur) => acc + cur, 0);
              respSku.netAmount = order?.amountDetails?.netAmount;
              respSku.totalDiscount = order?.amountDetails?.discountAmount;
              respSku.promotion = promotions;
          }
        })

      // to display group promotion as selected
        initialstate = initialstate.map((x)=>({
          ...x,
          promotion: x.promotion?.map((obj) => {
            if(obj.promotionScope==='GROUP'||obj.scope==='GROUP'){
              return {
              ...obj,
              promotionScope:'GROUP',
              status: resData?.orders.map((order)=>order.promotions?.filter((a)=>a.id===obj.id)).flat().length>0,
              quantity: obj.quantity,
              };
          }else{
            return obj
          }
          })
        }));
           setData({ ...data, skus: [...initialstate] });
           resetDialog();
        }
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    })
  }

  const groupPromotionSKU = (dialogData , type) =>{
    const { calculateAllPromotionDiscounts } = props;
    let newInput = finalDataToCalculateDiscounts.map(({ promotionData, ...x }) => x)

    calculateAllPromotionDiscounts({
      input: newInput,
      categoryId: outletBasic?.categoryId, distributorId: callPlanBasic?.distributorId, callPlanId: Number(id)
    }, {
      handleSuccess: (response) => {
        const resData = response.data?.calculateAllPromotionDiscounts;
        const validPromotion = [].concat(...resData?.orders?.map((a) => a.promotions?.map((b) => b.id)))
        if (validPromotion.includes(dialogData.promotionId)) {
          if(type === OPERATION.PROMOTION){
            setDialog({
            type :OPERATION.ADD_ORDER,
            element: dialogData
            })
          }
          // dialogData.promotion=dialogData?.promotion?.map((x)=>({ ...x,status:true }))
          const foundIndex = groupSKU.findIndex((x)=>x.promotionId===dialogData?.promotionId)
                if (foundIndex > -1) {
                  groupSKU[foundIndex] = {
                    element:dialogData?.promotionSkus?.map((p) => ({
                    ...p,
                    promotion: dialogData?.[0]?.promotion || [],
                    promotionId: dialogData?.promotionId,
                    freeSku: dialogData?.freeSku
                  })
                  ),
                  promotionId: dialogData?.promotionId
                }
                dialogData.quantity = dialogData?.promotionSkus?.find((a)=>a.id===dialogData?.id)?.quantity;
                }else{
                groupSKU.push({
                  element:dialogData?.promotionSkus?.map((p) => ({
                  ...p,
                  promotion: dialogData?.[0]?.promotion || [],
                  promotionId: dialogData?.promotionId,
                  freeSku: dialogData?.freeSku
                })
                ),
                promotionId: dialogData?.promotionId
              })
              dialogData.quantity = dialogData?.promotionSkus?.find((a)=>a.id===dialogData?.id)?.quantity;
            }
                setGroupSKU(groupSKU)
                seteditGroupPromotion(groupSKU);
                if(type === OPERATION.EDIT_PROMOTION){
                  return multiplePromotionFreeSKU([], data.skus, {}, false, dialogData , type)
                }
        } else {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER,'Provided input does not satisfy the promotion critera.')
        }
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    })

  }

  const handleDialogSubmit = (type, dialogData) => {

    /**
       * For planned, skuSummaryDetails have sku with quantity > 0 and pfdTarget > 0
       * To calculate discount, only pass skus having quantity > 0
       */
    if(type===OPERATION.ADD_ORDER||type===OPERATION.PROMOTION||type===OPERATION.EDIT){
    if(dialogData?.promotion?.length>0){
     const freeSkuPromotions = dialogData?.promotion?.find((x)=>x.freeSkus?.length>0)
       if(freeSkuPromotions && dialogData?.freeSku?.length===0 ){
      return displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Free SKU must be selected')
       }
     }
    }
    const prevSKUDetail = data.skus.filter((sku) => sku?.quantity > 0);

    let newData =[];
    if(dialogData.promotionSkus){
    const filteredNewData = dialogData.promotionSkus.filter((x)=>x.quantity>0);
     filteredNewData.map((s) => {
      newData.push({
        skuId:s.id,
        familyId:s.familyId || s.skuFamily?.id || 0,
        rateDetails: {
          rlp: s.Rates?.[0].priceDetails?.rlp
        },
        quantity: s.quantity || 0,
        promotionDiscount: 0,
        topUpDiscount: 0,
        billDiscount: 0,
        promotions: [],
        freeSku:false,
        skuBatchRateId: s.Rates?.[0].id,
        promotionData: dialogData.promotion,
        promotionId: dialogData?.promotionId || 0,
      });

      });
      if(dialogData?.freeSku?.length>0){
      const freeSkuData = dialogData?.freeSku?.filter((x)=>x.selected);
      const sum = freeSkuData.map((a)=>a.quantity).reduce((acc, cur) => acc + cur, 0);

      if(freeSkuData?.length>=1 && sum!==freeSkuData?.[0].maxQuantity){
        displayAlert(ALERT_TYPE.WARNING,`Sum of quantity must be equal to ${freeSkuData?.[0].maxQuantity}`)
        return
      }

      freeSkuData.forEach((x,i)=>newData.push({
        billDiscount: 0,
        promotionDiscount: 0,
        topUpDiscount: 0,
        promotions:[],
        freeSku: true,
        promotionId: dialogData?.promotionId,
        quantity: freeSkuData[i].quantity,
        rateDetails: { rlp: freeSkuData?.[i].rateDetails?.rlp },
        skuBatchRateId: freeSkuData?.[i].skuBatchRateId,
        skuId: freeSkuData?.[i].skuId,
        familyId: freeSkuData?.[i].familyId,

      }));
    }
  }else{
      newData = [{
      skuId: dialogData.id,
      familyId: dialogData.familyId || dialogData.skuFamily?.id || 0,
      rateDetails: {
        rlp: dialogData.Rates?.[0]?.priceDetails?.rlp
      },
      quantity: dialogData.quantity || 0,
      promotionDiscount: 0,
      topUpDiscount: 0,
      billDiscount: 0,
      promotions: [],
      freeSku: false,
      skuBatchRateId: dialogData.Rates?.[0]?.id,
      promotionData:dialogData.promotion,
      promotionId: dialogData?.promotionId || 0,
    }];
    if(dialogData?.freeSku?.length > 0){
      const freeSkuData = dialogData?.freeSku?.filter((x)=>x.selected);
      const sum = freeSkuData.map((a)=>a.quantity).reduce((acc, cur) => acc + cur, 0);
      if(freeSkuData?.length>=1 && sum!==freeSkuData?.[0].maxQuantity){
        displayAlert(ALERT_TYPE.WARNING,`Sum of quantity must be equal to ${freeSkuData?.[0].maxQuantity}`)
        return
      }
      freeSkuData.forEach((x,i)=>newData.push({
        billDiscount: 0,
        promotionDiscount: 0,
        topUpDiscount: 0,
        promotions:[],
        freeSku: true,
        promotionId: dialogData?.promotionId || dialogData?.promotion?.[0]?.id,
        quantity: freeSkuData[i].quantity,
        rateDetails: { rlp: freeSkuData?.[i].rateDetails?.rlp },
        skuBatchRateId: freeSkuData?.[i].skuBatchRateId,
        skuId: freeSkuData?.[i].skuId,
        familyId: freeSkuData?.[i].familyId,

      }));
    }
  }
    setnewOrderData(newData);

      if(oldData.length===0){
        finalDataToCalculateDiscounts = newData.map((a) => {return { ...a }})
        setoldData([...newData]);
      }
      else
      {
       (type===OPERATION.DELETE||type===OPERATION.DELETE_PROMOTION)?deleteData(newData,oldData): ReConstructData(newData,oldData)
      }

    if (type === OPERATION.DELETE_PROMOTION) {
      const index = groupSKU.findIndex((x)=>x.promotionId===dialogData?.promotionId)
      if (index > -1) {
        groupSKU.splice(index, 1);
        setGroupSKU([...groupSKU]);
        seteditGroupPromotion([...groupSKU]);
      }
    }

// OPERATION.PROMOTION => dialog when directed from skutabs,  OPERATION.EDIT_PROMOTION => dialog when directed from orderPad
    if (type === OPERATION.PROMOTION || type === OPERATION.EDIT_PROMOTION) {

      const promotionSkus = dialogData?.promotionSkus?.filter((p) => p.quantity > 0);
      const freeSkus = dialogData?.freeSku?.filter((a) => a.selected);
// groupPromotion with freeSKUs
      if (dialogData.freeSku?.length > 0) {
        const statusOfFreeSKU = dialogData.freeSku?.filter((a) => a.selected);
          if (statusOfFreeSKU.length > 0) {
            promotionSkus.push(freeSkus?.[0])
            dialogData.promotion=dialogData?.promotion?.map((x)=>({ ...x,status:x.id===dialogData?.promotionId }))
            return groupPromotionSKU(dialogData , type)
          } else {
            return displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Free SKU must be selected')
          }
      }

      // groupPromotion without freeSKUs
      dialogData.promotion=dialogData?.promotion?.map((x)=>({ ...x,status:x.id===dialogData?.promotionId }))
      return groupPromotionSKU(dialogData , type)
    }

    if (type === 'NO_ORDER') {
      const reasonStatus = !(dialogData.reason.length > 0);
      if(tasValidateSet?.nordervalidation && reasonStatus){
        return displayAlert(ALERT_TYPE.WARNING, 'Insert No Order Reason')
      }
      else{
      setNoOrder([
        {
          sku_id: dialogData.id,
          rlp: dialogData.rlp,
          reasonsList: `${dialogData.reason} ${dialogData.message}`,
          brandId: dialogData.brandId
        },
        ...noOrder,
      ])
    }
    }
    multiplePromotionFreeSKU([], data.skus, {}, false, dialogData , type)
  }

  const sortData = useCallback((sortedData) => {
    setData({ ...data, skus: [...sortedData] });
  }, [data.skus])

  const handleSKUQntyChange = (item, value, initialstate, stateUpdater, paramList) => {
    const promotionSkus = initialstate?.promotionSkus?.filter((p) => p.quantity > 0);
    const input = initialstate?.promotionSkus?.[paramList[1]];
    if (promotionSkus.find((a) => a.id === input.id)) {
      const promotions = promotionSkus.map((a) => ({
        ...a,
        selected: false
      }))
      initialstate.freeSku = [];
      stateUpdater(initialstate);
      return multiplePromotionFreeSKU([...promotions], initialstate, stateUpdater, true,{});
    } else {
      return multiplePromotionFreeSKU([...promotionSkus, input], initialstate, stateUpdater, true,{});
    }
  }

  const handleCompleteOrder = () => {
    if (summaryRef.current.totalLines === 0) {
      return displayAlert(ALERT_TYPE.WARNING, 'You need to take order first')
    }
    setShowBillingDeliveryPanel(true);
  }

  const handleCloseBillingDeliveryPanel = () => {
    setShowBillingDeliveryPanel(false);
  }

  const {
    outletBasic,
    callPlanBasic,
    outletAchievement,
    APGP,
    callHistory,
    outStandingOverDue,
    ULPBLP,
    brandEnrollment,
    p3m_average,
    avg_sales_per_bill
  } = data;
  const IS_PLANNED = ['planned', 'completed'].includes(data?.callPlanBasic?.planstatus?.toLowerCase() || state?.callPlanStatus || 'Pending');
  const IS_COMPLETED = ['completed'].includes(data?.callPlanBasic?.planstatus?.toLowerCase() || state?.callPlanStatus || 'Pending');
  const images = outletBasic && outletBasic.image_url ? _.sortBy(outletBasic?.image_url,['created_at'])?.reverse()?.map((imgList) => imgList.image)?.slice(0, 2) : [];
  const { serverResponseWaiting, SaveTASUnsuccessfulCalls, saveTasSuccessfulCalls, displayAlert, getMasterData } = props;
  const IN_PLANNED =callPlanBasic?.planstatus?.trim() === 'PLANNED';
  const buSettings = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.BU_SETTINGS));
  return (
    <>
      {dialog.type && (
        <DialogContainer className='dialog-wrap'>
          <DialogFormWrapper
            disableDialogClose={true}
            enableNoOrderBtn= {dialog.element?.isSBDSku}
            formConfig={formConfig[dialog.type]}
            dialogElement={dialog.element}
            formTitle={dialog.type === OPERATION.ADD_ORDER && dialog.element?.title}
            onDialogSubmit={handleDialogSubmit}
            onDialogCancel={resetDialog}
            onNoOrderClick={handleRowClick}
            withOutPadding={dialog.type === OPERATION.NO_ORDER}
            type={dialog.type}
            disableBackIcon={showSummaryOverlay}
            loading={serverResponseWaiting}
            renderDialog={({ dialogData, handleInputChange, handleRadioButtonChange, refsObj, enableErrorDisplay }) => (
              <>
                {(dialog.type === OPERATION.ADD_ORDER || dialog.type === OPERATION.EDIT) && (
                  <SKUForm
                    type={dialog.type}
                    data={dialogData}
                    loading={serverResponseWaiting}
                    onInputChange={handleInputChange}
                    inputCallBack={handleQntyChange}
                    handleIconClick={handlePromotionClick}
                    handleFreeSKUQuantity={handleFreeSKU}
                    lastInvoiceDate={data?.outletBasic?.lastInvoiceDate}
                  />
                )}
                {(dialog.type === OPERATION.NO_ORDER) && (
                  <NoOrderForm
                    data={dialogData}
                    onRadioButtonChange={handleRadioButtonChange}
                    onInputChange={handleInputChange}
                    handleRadioCallback={handleRadioCallback}
                    enableErrorDisplay={enableErrorDisplay}
                    refsObj={refsObj}
                    noOrdervalidation={tasValidateSet?.nordervalidation}
                  />
                )}
                {(dialog.type === OPERATION.PROMOTION || dialog.type===OPERATION.EDIT_PROMOTION) && (
                  <MultiplePromotionForm
                    data={dialogData}
                    loading={serverResponseWaiting}
                    onInputChange={handleInputChange}
                    inputCallBack={handleSKUQntyChange}
                    handleMultipleFreeSKU={handleMultipleFreeSKU}
                  />
                )}
                {(dialog.type === OPERATION.DELETE_PROMOTION||dialog.type ===OPERATION.DELETE)&&(
                  'Are you sure you want to delete this record?'
                )}
              </>
            )}
          />
        </DialogContainer>
      )}
      <div className='section-header'>
        <PanelStyled className='padding-0'>
          <BreadCrumb list={breadCrumbConfig} />
          <PageHeader
            config={{
              title: outletBasic && outletBasic.title || 'Outlet Name',
            }}
          >
            {(!startCall && callPlanBasic?.planstatus?.toLowerCase() === CALLPLAN_TABS.PLANNED) && (
              <Button iconBtnSmall className='start-call' onClick={handleStartCall}>Start Call
              </Button>
            )}
            {startCall && (<Timer
              callSummary={callSummary.current}
              seconds={seconds}
              minutes={minutes}
              serverResponseWaiting={serverResponseWaiting}
              stopTimer={stopUnsuccessfulTimer}
              SaveTASUnsuccessfulCallsApi={SaveTASUnsuccessfulCalls}
              tasUserCallPlanId={id}
              callPlanDetailsData={data}
              displayAlert={displayAlert}
              callNotes={callNotes}
              getMasterData={getMasterData}
            />)}
          </PageHeader>
        </PanelStyled>
      </div>

      <CallPlanContainer className='section-content'>
        <OutletDetailsContainer className='create-ui-inner'>
          {!startCall && !IS_COMPLETED ? <>
            <ImageSlider images={images} />
            <div className='outlet-name'>
              <Icon iconName='store' />
              {data?.outletBasic?.title || 'Outlet Name'}
            </div>
          </>
            : null}
          <section className='outlet-details-container'>
            {startCall || IS_COMPLETED ?
              <div className='billing-notes'>
                <h3>Call Notes</h3>
                <textarea rows={10} value={IS_COMPLETED ? callPlanBasic?.remarks : callNotes} disabled={IS_COMPLETED} onChange={editCallNotes} />
              </div>
              : null}
            {!IS_PLANNED ? <ScheduleCall handleCallScheduleChange={handleCallScheduleChange} displayAlert={displayAlert}/> : null}
            <OutletDetails callPlan={callPlanBasic} outletBasic={outletBasic} outletAchievement={outletAchievement} APGP={APGP} p3mAverage={p3m_average} avgSalesPerBill={avg_sales_per_bill} />
            <CallHistory displayAlert={displayAlert} outletBasic={outletBasic} callHistory={callHistory} ULPBLP={ULPBLP} />
            {brandEnrollment?.length > 0 ?
              <BrandEnrollment brandEnrollment={brandEnrollment} />
              : null
            }
            {outStandingOverDue?.outStandingAmount ?
              <OutstandingOverdue outStandingOverDue={outStandingOverDue} />
              : null
            }
          </section>
          {/* <div className='skus-nav'>
            {skuNavs.map((nav, idx) => (
              <Button key={`${nav.title}-${idx}`} outlined onClick={nav.onClick}>
                {nav.title}
                <Icon iconName='angle-right' />
              </Button>
            ))}
          </div> */}
        </OutletDetailsContainer>

        <SKUDetailsContainer>
          {!IS_COMPLETED ?
            <>
              <Tabs  selectedIndex={activeTab} onSelect={(tabIndex) => onTabChange(tabIndex)}>
                <TabList>
                      { IN_PLANNED &&
                  <Tab>
                      <span className='tab-label'>Call Target</span>
                    </Tab>
                    }
                  <Tab>
                    <span className='tab-label'>Top 10 SKUs</span>
                  </Tab>
                  <Tab>
                    <span className='tab-label'>Recommended SKUs</span>
                  </Tab>
                  {buSettings.sbd &&
                    <Tab>
                      <span className='tab-label'>SBD SKUs</span>
                    </Tab>
                  }
                 {buSettings.focusedSKU &&
                    <Tab>
                      <span className='tab-label'>Focused SKUs</span>
                    </Tab>
                  }
                  <Tab>
                    <span className='tab-label'>A-Z</span>
                  </Tab>
                </TabList>
                <div className='tab-container'>
                { IN_PLANNED &&
            <TabPanel>
                <SKUTab showSBDSkuTag planstatus={callPlanBasic?.planstatus} startCall={startCall} data={data.skus} isPlanned={IS_PLANNED} skuFilterCondition={(sku) => sku.pfdtarget > 0} handleCallTarget={handleCallTargetChange} handleRowClick={callPlanBasic?.planstatus === 'PLANNED' ? handleRowClick : () => null} lastInvoiceDate={data?.outletBasic?.lastInvoiceDate} />
              </TabPanel>
              }
                  <TabPanel>
                    <SKUTab showSBDSkuTag planstatus={callPlanBasic?.planstatus} startCall={startCall} data={data.skus} isPlanned={IS_PLANNED} skuFilterCondition={(sku) => sku.isTopSku}handleCallTarget={handleCallTargetChange} handleRowClick={callPlanBasic?.planstatus === 'PLANNED' ? handleRowClick : () => null} lastInvoiceDate={data?.outletBasic?.lastInvoiceDate}/>
                  </TabPanel>
                  <TabPanel>
                    <SKUTab showSBDSkuTag planstatus={callPlanBasic?.planstatus} startCall={startCall} data={data.skus} isPlanned={IS_PLANNED} skuFilterCondition={(sku) => sku.isRecommendedSku} handleCallTarget={handleCallTargetChange} handleRowClick={callPlanBasic?.planstatus === 'PLANNED' ? handleRowClick : () => null}
                    lastInvoiceDate={data?.outletBasic?.lastInvoiceDate} />
                  </TabPanel>
                  {buSettings.sbd &&
                    <TabPanel>
                      <SKUTab planstatus={callPlanBasic?.planstatus} startCall={startCall} data={data.skus} isPlanned={IS_PLANNED} skuFilterCondition={(sku) => sku.isSBDSku} handleCallTarget={handleCallTargetChange} handleRowClick={callPlanBasic?.planstatus === 'PLANNED' ? handleRowClick : () => null}lastInvoiceDate={data?.outletBasic?.lastInvoiceDate} />
                    </TabPanel>
                  }
                  {buSettings.focusedSKU &&
                    <TabPanel>
                      <SKUTab showSBDSkuTag planstatus={callPlanBasic?.planstatus} startCall={startCall} data={data.skus} isPlanned={IS_PLANNED} skuFilterCondition={(sku) => sku.isFocusedSku} handleCallTarget={handleCallTargetChange} handleRowClick={callPlanBasic?.planstatus === 'PLANNED' ? handleRowClick : () => null} lastInvoiceDate={data?.outletBasic?.lastInvoiceDate} />
                    </TabPanel>
                  }
                  <TabPanel>
                    <SKUTab showSBDSkuTag showSearch planstatus={callPlanBasic?.planstatus} startCall={startCall} data={data.skus} isPlanned={IS_PLANNED} handleCallTarget={handleCallTargetChange} handleRowClick={callPlanBasic?.planstatus === 'PLANNED' ? handleRowClick : () => null} lastInvoiceDate={data?.outletBasic?.lastInvoiceDate} />
                  </TabPanel>
                </div>
              </Tabs>
              {IS_PLANNED
                ? <OrderPadDrawer isCompleted={IS_COMPLETED} showSummaryOverlay={showSummaryOverlay} timer={`${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`} toggleOverlay={toggleOverlay} closeOverlay={closeOverlay} overlaySummary={summaryRef.current} orderPadSummaryDetails={skuSummaryDetails.current} handleCompleteOrder={handleCompleteOrder} handleEditOrder={handleRowClick} handleRemoveOrder={handleDialogSubmit}
                  handlePromotionClick={handleEditPromotion} editGroupPromotion={editGroupPromotion} orderPadDetail={orderPadDetail?.orders} />
                : <CallPlanDrawer isCompleted={IS_COMPLETED} showSummaryOverlay={showSummaryOverlay} closeOverlay={closeOverlay} toggleOverlay={toggleOverlay} overlaySummary={summaryRef.current} callTargetSummaryDetails={skuSummaryDetails.current} handleCallTarget={handleCallTargetChange} saveCallTarget={handleSaveCallTarget} />
              }
            </>
            : <CompletedOrders overlaySummary={summaryRef.current} orderSummaryDetails={skuSummaryDetails.current} callDuration={callPlanBasic?.call_time} />
          }
          {showBillingDeliveryPanel ?
            <BillingDeliveryPanel displayAlert={displayAlert} saveTasSuccessfulCalls={saveTasSuccessfulCalls} showSummaryOverlay={showBillingDeliveryPanel} closeOverlay={handleCloseBillingDeliveryPanel} timer={`${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`} callSummary={callSummary.current} summary={summaryRef.current} orderSummary={skuSummaryDetails.current} stopTimer={stopTimer} callPlanDetails={callPlanPayload.current} noOrder={noOrder} callNotes={callNotes} /> : null
          }
        </SKUDetailsContainer>
      </CallPlanContainer>
    </>
  )
}

export default withAlert()(withLoading(CallPlanDetails))

